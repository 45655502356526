import { sendRequest } from 'config/api';
import { STARTVIDEOTRACKING } from './types/trackVideoTypes';

export const startTrackingVideo = (lessonProgressInfo) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      '/lms/course/lesson/progress/',
      lessonProgressInfo,
      'POST',
    );
    dispatch({
      type: STARTVIDEOTRACKING,
      payload: data,
    });
  } catch (error) {
    console.log(error, 'this');
  }
};

export const updateVideoProgress = (
  lessonProgressInfo,
  progressId,
) => async () => {
  try {
    await sendRequest(
      `/lms/course/lesson/progress/${progressId}/`, //variable represents the progressId
      lessonProgressInfo,
      'PATCH',
    );
  } catch ({ message, response }) {
    console.log(message, 'frm backend error', response);
  }
};

