import React from 'react';
import BackIconLogo from 'assets/svgFiles/back-icon.svg';
import './BackIcon.scss';

export const BackIcon = () => {
  const goBack = (e) => {
 e = e || window.event; // support  for IE8 and lower
 e.preventDefault(); // stop browser from doing native logic
 window.history.go(-1);

  };
  
  return (
    <div>
      <img
        src={BackIconLogo}
        alt="back icon"
        onClick={(e)=>goBack(e)}
        className="back-icon"
      />
    </div>
  );
};
