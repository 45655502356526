export const GET_SUBSCRIPTION_PLANS_REQUEST = 'GET_SUBSCRIPTION_PLANS_REQUEST';
export const GET_SUBSCRIPTION_PLANS_SUCCESS= 'GET_SUBSCRIPTION_PLANS_SUCCESS';
export const GET_SUBSCRIPTION_PLANS_FAILURE = 'GET_SUBSCRIPTION_PLANS_FAILURE';
export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export const SUBSCRIBE_SUCCESS= 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE';
export const GET_SUBSCRIBED_INVOICE_REQUEST = 'GET_SUBSCRIBED_INVOICE_REQUEST';
export const GET_SUBSCRIBED_INVOICE_SUCCESS= 'GET_SUBSCRIBED_INVOICE_SUCCESS';
export const GET_SUBSCRIBED_INVOICE_FAILURE = 'GET_SUBSCRIBED_INVOICE_FAILURE';
export const INVOICE_PAYMENT_REQUEST = 'INVOICE_PAYMENT_REQUEST';
export const INVOICE_PAYMENT_SUCCESS= 'INVOICE_PAYMENT_SUCCESS';
export const INVOICE_PAYMENT_FAILURE = 'INVOICE_PAYMENT_FAILURE';
export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS= 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';
export const GET_CURRENT_SUBSCRIPTION_REQUEST = 'GET_CURRENT_SUBSCRIPTION_REQUEST';
export const GET_CURRENT_SUBSCRIPTION_SUCCESS= 'GET_CURRENT_SUBSCRIPTION_SUCCESS';
export const GET_CURRENT_SUBSCRIPTION_FAILURE = 'GET_CURRENT_SUBSCRIPTION_FAILURE';
