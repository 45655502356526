import {
  GET_USER_SYSTEM_USAGE_BEGIN,
  GET_USER_SYSTEM_USAGE_SUCCESS,
  GET_USER_SYSTEM_USAGE_FAILURE,
  GET_USER_ENGAGEMENT_SUCCESS,
  GET_USER_ENGAGEMENT_BEGIN,
  GET_USER_ENGAGEMENT_FAILURE,
  GET_USER_LEADERBOARD_BEGIN,
  GET_USER_LEADERBOARD_SUCCESS,
  GET_USER_LEADERBOARD_FAILURE,
  GET_USER_COURSE_BEGIN,
  GET_USER_COURSE_SUCCESS,
  GET_USER_COURSE_FAILURE,
  GET_LEARNING_GROUP_REPORT_BEGIN,
  GET_LEARNING_GROUP_REPORT_FAILURE,
  GET_LEARNING_GROUP_REPORT_SUCCESS,
  GET_SYSTEM_COURSES_SUCCESS,
  GET_SYSTEM_COURSES_BEGIN,
  GET_SYSTEM_COURSES_FAILURE,
  GET_SYSTEM_STUDENTS_BEGIN,
  GET_SYSTEM_STUDENTS_SUCCESS,
  GET_SYSTEM_STUDENTS_FAILURE,
  GET_SYSTEM_TRAFFIC_BEGIN,
  GET_SYSTEM_TRAFFIC_SUCCESS,
  GET_SYSTEM_TRAFFIC_FAILURE,
  GET_COURSE_VISITS_BEGIN,
  GET_COURSE_VISITS_SUCCESS,
  GET_COURSE_VISITS_FAILURE,
  GET_COURSE_PERFORMANCE_FAILURE,
  GET_COURSE_PERFORMANCE_SUCCESS,
  GET_COURSE_PERFORMANCE_BEGIN,
} from 'actions/types/reportTypes';

const initialState = {
  user: {
    fetchingSystemUsage: false,
    fetchingEngagement: false,
    fetchingCourse: false,
    fetchingLeaderBoard: false,
    systemUsage: null,
    leaderBoard: null,
    engagement: null,
    course: null,
    error: null,
  },
  learningGroup: {
    fetchingLearningGroupReport: false,
    course: null,
    error: null,
  },
  system: {
    fetchingSystemCoursesReport: false,
    fetchingSystemStudentsReport: false,
    fetchingSystemTrafficReport: false,
    courses: null,
    students: null,
    traffic: null,
    error: null,
  },
  course: {
    fetchingCourseVisitReport: false,
    fetchingCoursePerformanceReport: false,
    visits: null,
    performance,
    error: null,
  },
};
const reportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USER_SYSTEM_USAGE_BEGIN:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_SYSTEM_USAGE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_SYSTEM_USAGE_FAILURE:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };

    case GET_USER_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_ENGAGEMENT_BEGIN:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_ENGAGEMENT_FAILURE:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_LEADERBOARD_BEGIN:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_LEADERBOARD_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_LEADERBOARD_FAILURE:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_COURSE_BEGIN:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_COURSE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_USER_COURSE_FAILURE:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case GET_LEARNING_GROUP_REPORT_BEGIN:
      return {
        ...state,
        learningGroup: { ...state.learningGroup, ...payload },
      };
    case GET_LEARNING_GROUP_REPORT_SUCCESS:
      return {
        ...state,
        learningGroup: { ...state.learningGroup, ...payload },
      };
    case GET_LEARNING_GROUP_REPORT_FAILURE:
      return {
        ...state,
        learningGroup: { ...state.learningGroup, ...payload },
      };
    case GET_SYSTEM_COURSES_BEGIN:
      return {
        ...state,
        system: { ...state.system, ...payload },
      };
    case GET_SYSTEM_COURSES_SUCCESS:
      return {
        ...state,
        system: { ...state.system, ...payload },
      };
    case GET_SYSTEM_COURSES_FAILURE:
      return {
        ...state,
        system: { ...state.system, ...payload },
      };
    case GET_SYSTEM_STUDENTS_BEGIN:
      return {
        ...state,
        system: { ...state.system, ...payload },
      };
    case GET_SYSTEM_STUDENTS_SUCCESS:
      return {
        ...state,
        system: { ...state.system, ...payload },
      };
    case GET_SYSTEM_STUDENTS_FAILURE:
      return {
        ...state,
        system: { ...state.system, ...payload },
      };
    case GET_SYSTEM_TRAFFIC_BEGIN:
    case GET_SYSTEM_TRAFFIC_SUCCESS:
    case GET_SYSTEM_TRAFFIC_FAILURE:
      return {
        ...state,
        system: { ...state.system, ...payload },
      };

    case GET_COURSE_VISITS_BEGIN:
    case GET_COURSE_VISITS_SUCCESS:
    case GET_COURSE_VISITS_FAILURE:
      return {
        ...state,
        course: { ...state.course, ...payload },
      };
    case GET_COURSE_PERFORMANCE_BEGIN:
    case GET_COURSE_PERFORMANCE_SUCCESS:
    case GET_COURSE_PERFORMANCE_FAILURE:
      return {
        ...state,
        course: { ...state.course, ...payload },
      };
    default:
      return state;
  }
};
export default reportReducer;
