import {
    GET_SUBSCRIPTION_PLANS_REQUEST,
    GET_SUBSCRIPTION_PLANS_SUCCESS,
    GET_SUBSCRIPTION_PLANS_FAILURE,
    SUBSCRIBE_FAILURE,
    SUBSCRIBE_SUCCESS,
    SUBSCRIBE_REQUEST,
    GET_SUBSCRIBED_INVOICE_REQUEST,
    GET_SUBSCRIBED_INVOICE_SUCCESS,
    GET_SUBSCRIBED_INVOICE_FAILURE,
    INVOICE_PAYMENT_REQUEST,
    INVOICE_PAYMENT_SUCCESS,
    INVOICE_PAYMENT_FAILURE,
    GET_INVOICE_REQUEST,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_FAILURE,
    GET_CURRENT_SUBSCRIPTION_REQUEST,
    GET_CURRENT_SUBSCRIPTION_SUCCESS,
    GET_CURRENT_SUBSCRIPTION_FAILURE
  } from 'actions/types/subscriptionTypes';
  
  const initialState = {
    isLoading: false,
    error: false,
    subscription: {},
    invoices: {},
    invoice: {},
    currentSubscription: {},
  };
  
  const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SUBSCRIPTION_PLANS_REQUEST:
        return {
          ...state,
         isLoading: true,
        };
        case GET_SUBSCRIPTION_PLANS_SUCCESS:
        return {
          ...state,
          subscription: action.payload,
          isLoading: false,
        };
        case GET_SUBSCRIPTION_PLANS_FAILURE:
        return {
          ...state,
          error: action.error,
          isLoading: false,
        };
        case SUBSCRIBE_REQUEST:
          return {
            ...state,
           isLoading: true,
          };
          case SUBSCRIBE_SUCCESS:
          return {
            ...state,
            isLoading: false,
          };
          case SUBSCRIBE_FAILURE:
          return {
            ...state,
            error: action.error,
            isLoading: false,
          };

          case GET_SUBSCRIBED_INVOICE_REQUEST:
            return {
              ...state,
             isLoading: true,
            };
            case GET_SUBSCRIBED_INVOICE_SUCCESS:
            return {
              ...state,
              invoices: action.payload,
              isLoading: false,
            };
            case GET_SUBSCRIBED_INVOICE_FAILURE:
            return {
              ...state,
              error: action.error,
              isLoading: false,
            };
            case INVOICE_PAYMENT_REQUEST:
            return {
              ...state,
             isLoading: true,
            };
            case INVOICE_PAYMENT_SUCCESS:
            return {
              ...state,
              isLoading: false,
              error: false,
            };
            case INVOICE_PAYMENT_FAILURE:
            return {
              ...state,
              error: true,
              isLoading: false,
            };

            case GET_INVOICE_REQUEST:
              return {
                ...state,
                isLoading: true,
              };
            case GET_INVOICE_SUCCESS:
              return {
                ...state,
                invoice: action.payload,
                isLoading: false,
                error: false,
              };
            case GET_INVOICE_FAILURE:
              return {
                ...state,
                error: true,
                isLoading: false,
              };
              case GET_CURRENT_SUBSCRIPTION_REQUEST:
              return {
                ...state,
                isLoading: true,
              };
            case GET_CURRENT_SUBSCRIPTION_SUCCESS:
              return {
                ...state,
                currentSubscription: action.payload,
                isLoading: false,
                error: false,
              };
            case GET_CURRENT_SUBSCRIPTION_FAILURE:
              return {
                ...state,
                error: true,
                isLoading: false,
              };
      default:
        return state;
    }
  };
  
  export default subscriptionReducer;
  