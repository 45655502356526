import {
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
  GET_MESSAGES_BEGIN,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_BEGIN,
  GET_CONVERSATION_FAILURE,
  SEND_MESSAGE_ERROR,
  DELETE_MESSAGE_ERROR,
  UPDATE_MESSAGE_LIST,
} from 'actions/types/messageTypes.js';

const initialState = {
  fetchingMessages: false,
  fetchingConversation: false,
  messages: null,
  conversation: null,
  error: null,
};

const MessagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_MESSAGES_BEGIN:
      return {
        ...state,
        ...payload,
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_MESSAGES_FAILURE:
      return {
        ...state,
        ...payload,
      };
    case GET_CONVERSATION_BEGIN:
      return {
        ...state,
        ...payload,
      };
    case GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_CONVERSATION_FAILURE:
      return {
        ...state,
        ...payload,
      };
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        ...payload,
      };
    case DELETE_MESSAGE_ERROR:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_MESSAGE_LIST:
      return {
        ...state,
        messages: {
          ...state.messages,
          results: state.messages.results.map(
            (message) =>
              payload.find((item) => item.sender.id === message.sender.id) ||
              message,
          ),

          // message.sender.id === payload.sender.id ? payload : message,
        },
      };
    default:
      return state;
  }
};

export default MessagesReducer;
