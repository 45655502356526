import {
  FETCH_QUESTION,
  IS_LOADING,
  ASSESSMENT_SENT_QUESTONS_SUCCESS,
  ASSESSMENT_QUESTONS_STATUS,
  ASSESSMENT_QUESTONS_RESULT,
  ASSESSMENT_COMPLETED
} from 'actions/types/questionTypes';

const initialState = {
  initial: true,
  loading: false,
  questions: [],
  status: '',
  assessmentresults: {},
  assessmentstatus:{
   
  },

};
const questionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_QUESTION:
      return {
        ...state,
        initial: false,
        questions: payload,
      };

    case IS_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };

    case ASSESSMENT_SENT_QUESTONS_SUCCESS:
      return {
        ...state,
        status: payload,
      };

    case   ASSESSMENT_QUESTONS_STATUS:
      return {
        ...state,
        assessmentstatus: payload,
      };

      case  ASSESSMENT_QUESTONS_RESULT:
        return {
          ...state,
          assessmentresults: payload,
        };
      case ASSESSMENT_COMPLETED:
        return {
          ...state,
          assessmentstatus: {
            ...state.assessmentstatus,
            status: 'COMPLETED'
          }
        }
    default:
      return state;
  }
};
export default questionReducer;
