import { SET_CURRENT_TAB } from 'actions/types';

const initialState = {
  currentUserTab: 'All Users',
};

const tabReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_TAB:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default tabReducer;
