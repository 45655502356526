export const GET_EVENTS = 'GET_EVENTS'
export const ADD_EVENT = 'ADD_EVENT'
export const SCHEDULE_FORM = 'SCHEDULE_FORM'
export const ADD_USER = 'ADD_USER'
export const LINK_FORM = 'LINK_FORM'
export const SEARCH_USER= 'SEARCH_USER'
export const BACK = 'BACK'
export const NEXT = 'NEXT'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const ADD_SELECTED = 'ADD_SELECTED'
export const REMOVE_SELECTED = 'REMOVE_SELECTED'
export const REMOVE_ALL_SELECTED = 'REMOVE_ALL_SELECTED'
export const USER = 'USER'
export const GROUP = 'GROUP'
export const ADD_LINK = 'ADD_LINK'
export const BEGIN_EDIT = 'BEGIN_EDIT'
export const EDIT_EVENT = 'EDIT_EVENT'