import {
  FETCH_LEARNING_GROUP_BEGIN,
  FETCH_LEARNING_GROUP_SUCCESS,
  FETCH_SINGLE_LEARNING_GROUP_SUCCESS,
  FETCH_SINGLE_LEARNING_GROUP_BEGIN,
  CREATE_LEARNING_GROUP_SUCCESS,
  DELETE_LEARNING_GROUP_BEGIN,
  DELETE_LEARNING_GROUP_SUCCESS,
  FETCH_LEARNING_GROUP_USERS_BEGIN,
  FETCH_LEARNING_GROUP_USERS_SUCCESS,
  FETCH_LEARNING_GROUP_COURSES_BEGIN,
  FETCH_LEARNING_GROUP_COURSES_SUCCESS,
  SEARCH_LEARNING_GROUP_BEGIN,
  SEARCH_LEARNING_GROUP_SUCESS,
  SEARCH_LEARNING_GROUP_USERS_SUCCESS,
  SEARCH_LEARNING_GROUP_USERS_BEGIN,
  SEARCH_LEARNING_GROUP_COURSES_BEGIN,
  SEARCH_LEARNING_GROUP_COURSES_SUCCESS,
} from '../actions/types/learningGroupTypes';

const initalState = {
  isLoading: false,
  isFetching: false,
  learningGroups: null,
  singleLearningGroup: null,
  learningGroupUsers: null,
  learningGroupCourses: null,
  message: '',
  isDeleting: false,
};

const learningGroupReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_LEARNING_GROUP_BEGIN:
      return {
        ...state,
        ...payload,
      };

    case FETCH_LEARNING_GROUP_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case CREATE_LEARNING_GROUP_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_SINGLE_LEARNING_GROUP_BEGIN:
      return {
        ...state,
        ...payload,
      };
    case FETCH_SINGLE_LEARNING_GROUP_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case DELETE_LEARNING_GROUP_BEGIN:
      return {
        ...state,
        ...payload,
      };
    case DELETE_LEARNING_GROUP_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case FETCH_LEARNING_GROUP_USERS_BEGIN:
      return {
        ...state,
        ...payload,
      };

    case FETCH_LEARNING_GROUP_USERS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_LEARNING_GROUP_COURSES_BEGIN:
      return {
        ...state,
        ...payload,
      };
    case FETCH_LEARNING_GROUP_COURSES_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case SEARCH_LEARNING_GROUP_BEGIN:
      return {
        ...state,
        ...payload,
      };
    case SEARCH_LEARNING_GROUP_SUCESS:
      return {
        ...state,
        ...payload,
      };
    case SEARCH_LEARNING_GROUP_USERS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case SEARCH_LEARNING_GROUP_USERS_BEGIN:
      return {
        ...state,
        ...payload,
      };

    case SEARCH_LEARNING_GROUP_COURSES_BEGIN:
      return {
        ...state,
        ...payload,
      };
    case SEARCH_LEARNING_GROUP_COURSES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default learningGroupReducer;
