export const GET_FILE_STORAGE = 'GET_FILE_STORAGE';
export const GET_FILE_STORAGE_ERROR = 'GET_FILE_STORAGE_ERROR';
export const GET_FILE_ENTRIES = 'GET_FILE_ENTRIES';
export const GET_FILE_ENTRIES_ERROR = 'GET_FILE_ENTRIES_ERROR';
export const SEARCH_FILE = 'SEARCH_FILE';
export const SEARCH_FILE_ERROR = 'SEARCH_FILE_ERROR';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR';
export const DELETE_FILE_ENTRY = 'DELETE_FILE_ENTRY';
export const DELETE_FILE_ENTRY_ERROR = 'DELETE_FILE_ENTRY_ERROR';
export const RENAME_FILE_ENTRY_ERROR = 'RENAME_FILE_ENTRY_ERROR';
export const RENAME_FILE_ENTRY = 'RENAME_FILE_ENTRY';
export const GET_DELETED_FILES = ' GET_DELETED_FILES';
export const GET_DELETED_FILES_ERROR = ' GET_DELETED_FILES_ERROR';
export const DELETE_FILE_PERMANENTLY_ERROR = '  DELETE_FILE_PERMANENTLY_ERROR';
export const DELETE_FILE_PERMANENTLY = '  DELETE_FILE_PERMANENTLY';
export const GET_SHARED_FILES = 'GET_SHARED_FILES';
export const GET_SHARED_FILES_ERROR = 'GET_SHARED_FILES_ERROR';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const RESTORE_FILE = 'RESTORE_FILE';
export const RESTORE_FILE_ERROR = 'RESTORE_FILE_ERROR';
export const GET_FOLDER_ENTRIES = 'GET_FOLDER_ENTRIES';
export const GET_FOLDER_ENTRIES_ERROR = 'GET_FOLDER_ENTRIES_ERROR';
export const SHARE_FILE = 'SHARE_FILE';
export const SHARE_FILE_ERROR = 'SHARE_FILE_ERROR';
export const UNSHARE_FILE_ERROR = 'UNSHARE_FILE_ERROR';
export const UNSHARE_FILE = 'UNSHARE_FILE';
export const SEARCH_SHARE_FILE_USERS = 'SEARCH_SHARE_FILE_USERS';
export const SEARCH_SHARE_FILE_USERS_ERROR = 'SEARCH_SHARE_FILE_USERS_ERROR';
