export const HANDLE_LOADING = 'HANDLE_LOADING';
export const  ADD_LESSONS = ' ADD_LESSONS';
export const GET_ERRORS = 'GET_ERRORS';
export const SET_LESSON_ERRORS = 'SET_LESSON_ERRORS'
export const GET_LESSON = 'GET_LESSON'
export const ADD_SINGLE_LESSON = 'ADD_SINGLE_LESSON'
export const UPDATE_LESSON = 'UPDATE_LESSON'
export const DELETE_LESSON = 'DELETE_LESSON'


