export const GET_USER_SYSTEM_USAGE_BEGIN = 'GET_USER_SYSTEM_USAGE_BEGIN';
export const GET_USER_SYSTEM_USAGE_SUCCESS = 'GET_USER_SYSTEM_USAGE_SUCCESS';
export const GET_USER_SYSTEM_USAGE_FAILURE = 'GET_USER_SYSTEM_USAGE_FAILURE';
export const GET_USER_ENGAGEMENT_BEGIN = 'GET_USER_ENGAGEMENT_BEGIN';
export const GET_USER_ENGAGEMENT_SUCCESS = 'GET_USER_ENGAGEMENT_SUCCESS';
export const GET_USER_ENGAGEMENT_FAILURE = 'GET_USER_ENGAGEMENT_FAILURE';
export const GET_USER_LEADERBOARD_BEGIN = 'GET_USER_LEADERBOARD_BEGIN';
export const GET_USER_LEADERBOARD_SUCCESS = 'GET_USER_LEADERBOARD_SUCCESS';
export const GET_USER_LEADERBOARD_FAILURE = 'GET_USER_LEADERBOARD_FAILURE';
export const GET_USER_COURSE_BEGIN = 'GET_USER_COURSE_BEGIN';
export const GET_USER_COURSE_SUCCESS = 'GET_USER_COURSE_SUCCESS';
export const GET_USER_COURSE_FAILURE = 'GET_USER_COURSE_FAILURE';
export const GET_LEARNING_GROUP_REPORT_BEGIN =
  'GET_LEARNING_GROUP_REPORT_BEGIN';
export const GET_LEARNING_GROUP_REPORT_SUCCESS =
  'GET_LEARNING_GROUP_REPORT_SUCCESS';
export const GET_LEARNING_GROUP_REPORT_FAILURE =
  'GET_LEARNING_GROUP_REPORT_FAILURE';
export const GET_SYSTEM_COURSES_BEGIN = 'GET_SYSTEM_COURSES_BEGIN';
export const GET_SYSTEM_COURSES_SUCCESS = 'GET_SYSTEM_COURSES_SUCCESS';
export const GET_SYSTEM_COURSES_FAILURE = 'GET_SYSTEM_COURSES_FAILURE ';
export const GET_SYSTEM_STUDENTS_BEGIN = 'GET_SYSTEM_STUDENTS_BEGIN';
export const GET_SYSTEM_STUDENTS_SUCCESS = 'GET_SYSTEM_STUDENTS_SUCCESS';
export const GET_SYSTEM_STUDENTS_FAILURE = 'GET_SYSTEM_STUDENTS_FAILURE ';
export const GET_SYSTEM_TRAFFIC_BEGIN = 'GET_SYSTEM_TRAFFIC_BEGIN';
export const GET_SYSTEM_TRAFFIC_SUCCESS = 'GET_SYSTEM_TRAFFIC_SUCCESS';
export const GET_SYSTEM_TRAFFIC_FAILURE = 'GET_SYSTEM_TRAFFIC_FAILURE ';
export const GET_COURSE_VISITS_BEGIN = 'GET_COURSE_VISITS_BEGIN';
export const GET_COURSE_VISITS_SUCCESS = 'GET_COURSE_VISITS_SUCCESS';
export const GET_COURSE_VISITS_FAILURE = 'GET_COURSE_VISITS_FAILURE ';
export const GET_COURSE_PERFORMANCE_BEGIN = 'GET_COURSE_PERFORMANCE_BEGIN';
export const GET_COURSE_PERFORMANCE_SUCCESS = 'GET_COURSE_PERFORMANCE_SUCCESS';
export const GET_COURSE_PERFORMANCE_FAILURE = 'GET_COURSE_PERFORMANCE_FAILURE ';
