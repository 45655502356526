import {IS_UPLOADING, FAILED_UPLOAD, SUCCESS_UPLOAD} from './types/progressTypes'

export const handleProgress = (uploadPercent) => (dispatch)=> (dispatch({
  type: IS_UPLOADING,
  payload: {
      percent: uploadPercent,
  }
}));

export const handleFailedUpload = () => (dispatch)=> (dispatch({
    type: FAILED_UPLOAD,
}));

export const handleSuccessUpload = (uploadPercent) => (dispatch)=> (dispatch({
    type: SUCCESS_UPLOAD,
}));