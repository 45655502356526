import { IS_LOADING } from 'actions/types/loadingType';

const initialState = {
  loading: false,
};

const loadingReducer = (state = initialState, { type}) => {
  switch (type) {
    case IS_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    default:
      return state;
  }
};

export default loadingReducer;