import { SET_COMPANY } from 'actions/types';
import {
  GET_SINGLE_COMPANY_REQUEST,
  GET_SINGLE_COMPANY_SUCCESS,
  GET_SINGLE_COMPANY_FAILURE,
} from 'actions/types/companyTypes';
import isEmpty from 'helpers/isEmpty';

const initialState = {
  company: {},
  singleCompany: {},
  validCompany: false,
  loading: false,
  error: false,
};

const companyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY:
      return {
        ...state,
        loading: false,
        company: payload,
        validCompany: !isEmpty(payload),
      };

    case GET_SINGLE_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        singleCompany: payload,
        isLoading: false,
        error: false,
      };
    case GET_SINGLE_COMPANY_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default companyReducer;
