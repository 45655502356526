import {
  ADD_LESSONS,
  GET_LESSON,
  SET_LESSON_ERRORS,
  UPDATE_LESSON
} from 'actions/types/lessonTypes';

const initialState = {
  loading: false,
  created: false,
  lessons: [],
  lesson: {},
  errorMessage: null,
};

const lessonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_LESSONS:
      return {
        ...state,
        created: true,
        lessons: payload,
      };
    case GET_LESSON:
      return {
        ...state,
        lesson: payload,
      }
    case UPDATE_LESSON: 
    return {
      ...state,
      lessons : [
        ...state.lessons,
        payload
      ]
    }
    case SET_LESSON_ERRORS:
      return {
        ...state,
        errorMessage: payload,
      };
    default:
      return state;
  }
};




export default lessonReducer;
