export const GET_ALLUSERS = 'GET_ALLUSERS';
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const EDIT_USER = 'EDIT_USER';
export const UPLOAD_USER = 'UPLOAD_USER';
export const GET_SINGLE_USER = 'GET_SINGLE_USER';
export const SEARCH_USER = 'SEARCH_USER';
export const GET_All_USERS = 'GET_All_USERS';
export const BUTTON_DISABLED = 'BUTTON_DISABLED';
export const RESET_PROGRESS = 'RESET_PROGRESS';
