import React from 'react';
import { css } from 'glamor';
import CheckIcon from '../../assets/pngFiles/check-icon.png';

function CheckMark() {
  const checkStyles = css({
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '16px',
    background: '#000',
    outline: 'none',
    border: 'none',
    // padding: 0,
    opacity: '0.7',
    transition: '.3s ease',
    position: 'absolute',
     margin: '13px 117px 13px 25px',
  });
  return <img {...checkStyles} src={CheckIcon} alt="check icon" />;
}

export default CheckMark;
