import { SEND_PAYMENT_RESPONSE} from 'actions/types/coursePaymentTypes';
  
  const initialState = {
    paymentResponse: {},
  };
  
  const coursePaymentReducer = (state = initialState, action) => {
    switch (action.type) {
      case SEND_PAYMENT_RESPONSE:
        return {
          ...state,
          paymentResponse: action.payload
        };
      default:
        return state;
    }
  };
  
  export default coursePaymentReducer;
  