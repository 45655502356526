import { SET_ACTIVE_ROLE,SET_LOADER, } from 'actions/types';

const initialState = {
  activeRole: 'STUDENT',
  status:false
};

const roleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_ROLE:
      return {
        ...state,
        activeRole: payload,
      };
   case SET_LOADER:
     return {
       ...state,
       status:payload,
     }
    default:
      return state;
  }
};

export default roleReducer;
