import {
  GET_COURSE,
//   GET_COURSES,
//   // SEARCH_COURSES,
//   // CATEGORY_COURSES,
//   GET_CATEGORIES,
//   GET_MY_COURSES,
  GET_MY_PENDING_COURSES,
  GET_MY_ACCEPTED_COURSES,
  GET_MY_REJECTED_COURSES,
GET_PENDING_COURSES,
  GET_ACCEPTED_COURSES,
  GET_REJECTED_COURSES,
} from 'actions/types';


const initialState = {
  courses: {},
  myPendingcourses: {},
  myAcceptedcourses: {},
  myRejectedcourses: {},
  pendingcourses: {},
  acceptedcourses: {},
  rejectedcourses: {},
  assigedCourses: {},
  categories: [],
  created: false,
  course: {
    modules: [],
    announcements: [],
    assignments: [],
    students: {
      results: [],
    },
    groups: {
      results: [],
    },
    loading: false,
  },
  error: null,
  adminPendingPaginatedCourses: [],
  adminAcceptedPaginatedCourses: [],
  adminRejectedPaginatedCourses: [],
  instructorPaginatedCourses: [],
  studentPendingPaginatedCourses: [],
  studentAcceptedPaginatedCourses: [],
  studentRejectedPaginatedCourses: [],
  paginatedCourses: [],
  studentCourses: {
    initial: true,
    inProgress: [],
    assigned: [],
  },
};

const courseEnrollmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          ...action.payload,
        },
      };

    case GET_PENDING_COURSES:
      if (action.payload.searchstatus) {
        state.adminPendingPaginatedCourses = [];
      }

      return {
        ...state,
        pendingcourses: action.payload.data,
        adminPendingPaginatedCourses: [
          ...state.adminPendingPaginatedCourses,
          ...action.payload.data.results,
        ],
      };

//admin
    case GET_ACCEPTED_COURSES:
      if (action.payload.searchstatus) {
        state.adminAcceptedPaginatedCourses = [];
      }

      return {
        ...state,
        acceptedcourses: action.payload.data,
        adminAcceptedPaginatedCourses: [
          ...state.adminAcceptedPaginatedCourses,
          ...action.payload.data.results,
        ],
      };

    case GET_REJECTED_COURSES:
      if (action.payload.searchstatus) {
        state.adminRejectedPaginatedCourses = [];
      }

      return {
        ...state,
        rejectedcourses: action.payload.data,
        adminRejectedPaginatedCourses: [
          ...state.adminRejectedPaginatedCourses,
          ...action.payload.data.results,
        ],
      };


//student
    case GET_MY_PENDING_COURSES:
      if (action.payload.searchstatus) {
        state.studentPendingPaginatedCourses = [];
      }

      return {
        ...state,
        myPendingcourses: action.payload.data,
        studentPendingPaginatedCourses: [
          ...state.studentPendingPaginatedCourses,
          ...action.payload.data.results,
        ],
      };

    case GET_MY_ACCEPTED_COURSES:
      if (action.payload.searchstatus) {
        state.studentAcceptedPaginatedCourses = [];
      }

      return {
        ...state,
        myAcceptedcourses: action.payload.data,
        studentAcceptedPaginatedCourses: [
          ...state.studentAcceptedPaginatedCourses,
          ...action.payload.data.results,
        ],
      };

    case GET_MY_REJECTED_COURSES:
      if (action.payload.searchstatus) {
        state.studentRejectedPaginatedCourses = [];
      }

      return {
        ...state,
        myRejectedcourses: action.payload.data,
        studentRejectedPaginatedCourses: [
          ...state.studentRejectedPaginatedCourses,
          ...action.payload.data.results,
        ],
      };

    default:
      return state;
  }
};

export default courseEnrollmentReducer;
