// eslint-disable-next-line no-unused-vars
import { SAVE_SETTINGS, GET_SETTINGS } from 'actions/types/customizationTypes';
import { sendRequest } from 'config/api';
import { toast } from 'react-toastify';
import {getSubdomain} from 'helpers/getSubdomain';
import {getCompany} from 'actions/authActions';



export const getSettings = (customization) => async (dispatch) => {
  try {
    
    if(customization){
      dispatch({
        type: GET_SETTINGS,
        payload: customization,
      });
    }
    return {
      success: true
    }
  } catch (error) {
    toast.error('Error getting custom settings. Kindly reload page')
  }
};

const refreshSettings = () => (dispatch) => {
  dispatch(getCompany(getSubdomain()));
}


export const saveSettings = (settingsData) => async (dispatch) => {
  const {
    
    brandColors,
    navColors,
    navBackgroundColors,
    companyBanner,
    bannerText,
    
    bannerDescription,
  } = settingsData;

//process for patch
  const patchFormData = {
    brand_color: brandColors.filter((each) => each.id),
    navigation_bar: navColors.filter((each) => each.id),
    background: navBackgroundColors.filter((each) => each.id),
    banners: companyBanner.filter(each => (each.file && each.id) || each.id),
    banner_title: bannerText,
    banner_description: bannerDescription,
  };

  let newPatchData = {
    banner_title: bannerText,
    banner_description: bannerDescription,
  };

  Object.entries(patchFormData).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      newPatchData[key] = value;
    }
  });

  const saveFormData = {
    brand_color: brandColors.filter((each) => !each.id),
    navigation_bar: navColors.filter((each) => !each.id),
    background: navBackgroundColors.filter((each) => !each.id),
    banners: companyBanner.filter(each => each.file && !each.id),
    banner_title: bannerText,
    banner_description: bannerDescription,
  };

  let newSaveData = {};

  Object.entries(saveFormData).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      newSaveData[key] = value;
    }
  });

  let patchRequestPromises = []
  let postRequestPromises = []

//images, it is possible that only the active key changed or the file was entirely replaced
  if(newPatchData.banners){
    Object.entries(newPatchData).forEach(([key, value]) => {
      if(key === 'banners'){
        return value.forEach((each, index) => {
         
          let finalData;
          if(each.id && each.file){
             // a replacement of the file occured
            finalData= new FormData();
            finalData.append('id', each.id);
            finalData.append('image', each.file);
            finalData.append('active', each.active);
          }else{
            if(each.id){
               // active field changed
              finalData = new FormData();
              finalData.append('id', each.id);
              finalData.append('active', each.active);
            }
          }
          
          if(finalData){
            patchRequestPromises.push(sendRequest(`/org/company/banner/${each.id}/`, finalData, 'PATCH'))
          }
         })
        
      }
    })
  }

//images, for new additions
  if(newSaveData.banners){
    Object.entries(newSaveData).forEach(([key, value]) => {
      if(key === 'banners'){
        return value.forEach((each, index) => {
          let finalData;
          // a new file added
          if(!each.id && each.file){
            //formdata
            finalData= new FormData();
            finalData.append('image', each.file);
            finalData.append('active', each.active);
          }
          if(finalData){
            postRequestPromises.push(sendRequest(`/org/company/banner/`, finalData, 'POST'))
          }
          
        })
        
      }
    })
  }

  const getSaveData = (newSaveData) => {
    const { banners:postBanners, ...restOfPostData} = newSaveData;
    return {...restOfPostData}
  }

  const getPatchData = (newPatchData) => {
    const { banners:patchBanners, ...restOfPatchData} = newPatchData;
    return {...restOfPatchData}
  }
  
  try {
    
    
    sendRequest('/org/company/customization/', getPatchData(newPatchData), 'PATCH')
     await Promise.all(
      Object.values(newSaveData).length > 0
        ? [
            sendRequest('/org/company/customization/', getSaveData(newSaveData), 'POST'),
            ...postRequestPromises,
            sendRequest('/org/company/customization/', getPatchData(newPatchData), 'PATCH'),
            ...patchRequestPromises,
          ]
        : [sendRequest('/org/company/customization/', getPatchData(newPatchData), 'PATCH'), ...patchRequestPromises],
    );
    dispatch(refreshSettings())
    toast.success('Custom changes saved');
  } catch (error) {
    console.log(error);
  }
};
