/**
 *
    1.
    Input: Object - returns the last error message
    - {
      "amount": ["A valid integer is required."], "description": ["This field may not be blank."]
      }
    Output:
    - description: This field may not be blank.

    2.
    Input: Object with unwanted key - returns the error message
    - { "detail": "You do not have permission to perform this action" }
    Output:
    - You do not have permission to perform this action

    3.
    Input: Object with unwanted key - returns the error message
    - err = {
      "error": true,
      "message": "No User matches the given query.",
      "exception": "No User matches the given query."
      }
    Output:
    - No User matches the given query.
  *
**/

export const formatErrors = (errors) => {
  let error;
  Object.entries(errors).forEach((errorKey) => {
    error = `${ errorKey[1] }`
  });
  return error;
};

/**
 *
    1.
    Input: Object - returns the last error message
    - {
      "amount": ["A valid integer is required."], "description": ["This field may not be blank."]
      }
    Output:
    - description: This field may not be blank.

    2.
    Input: Array - returns the first error message
    - ["You cannot do this"]
    Output:
    - You cannot do this

    3.
    Input: Object with default detail key - returns the error message
    - { "detail": "You do not have permission to perform this action" }
    Output:
    - You do not have permission to perform this action

    4.
    Input: String - returns the string
    - 'This is an error message'
    Output:
    - This is an error message
 *
**/

export const parseErrors = (error) => {
  if (typeof error === 'string') {
    return error;
  }
  if (error.length) {
    return error[0];
  }
  if (Object.keys(error)) {
    return formatErrors(error);
  }
  return error;
};