import {
    // GET_ERRORS,
    HANDLE_LOADING,
  } from 'actions/types';
  import {
    GET_USERS,
  } from 'actions/types/leaderboardTypes';

  
  const initialState = {
    loading: false,
    users: { results: [] },
    results: [],
   
  };
  
  const leaderboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case HANDLE_LOADING:
        return {
          ...state,
          loading: true,
        };
      case GET_USERS:
        return {
          ...state,
          loading: false,
          users:{results:action.payload}
        };
      default:
        return state;
    }
  };
  
  export default leaderboardReducer;
  