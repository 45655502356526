import { sendRequest } from 'config/api';

import {
  ADD_LESSONS,
  GET_LESSON,
  SET_LESSON_ERRORS,
  ADD_SINGLE_LESSON,
  UPDATE_LESSON,
  DELETE_LESSON,
} from 'actions/types/lessonTypes';
import { GET_COURSE } from './types';
import { GET_ERRORS } from 'actions/types/moduleTypes';
import { updateCourseAfterMarking } from 'actions/courseActions';

import { handleLoading } from 'actions/loadingAction';
import { handleFailedUpload } from 'actions/progressActions';
import { RESET_PROGRESS } from './types/progressTypes';

export const getCourse = (courseId, userMode) => async (dispatch) => {
  try {
    dispatch(handleLoading());
    let url = userMode
      ? `/lms/course/${courseId}?user_mode=${userMode}`
      : `/lms/course/${courseId}`;

    const response = await sendRequest(url, null, 'GET');
    dispatch({
      type: GET_COURSE,
      payload: response.data,
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: GET_ERRORS,
      errorMessage: error.message,
    });
  }
};

export const addLessons = (lessons, moduleId, toast) => async (dispatch) => {
  dispatch(handleLoading());
  try {
    const lessonNames = Object.keys(lessons);

    let response = lessonNames.map((name) => {
      const formData = new FormData();
      const lesson = lessons[name];
      formData.append('title', lesson['title']);
      formData.append('lesson_type', [lesson['type']]);
      switch (lesson['type']) {
        case 'VIDEO':
          formData.append('video', lesson['video']['value']);
          break;
        case 'TEXT':
          formData.append('text', lesson['text']['value']);
          break;
        case 'DOCUMENT':
          formData.append('doc', lesson['file']['value']);
          break;
        default:
          break;
      }
      formData.append('module', moduleId);
      return sendRequest('/lms/lesson/', formData, 'POST', undefined, dispatch);
    });

    response = await Promise.all(response);
    dispatch({
      type: ADD_LESSONS,
      payload: response,
    });
    dispatch(handleLoading());
    return;
  } catch (error) {
    const errorMessage = error.response || error.message; //we have to look for away to get proper error  from the response object..
    toast.error(errorMessage);
    dispatch({
      type: SET_LESSON_ERRORS,
      payload: error.message,
    });

    dispatch(handleLoading());
  }
};

export const getLesson = (lessonId) => async (dispatch) => {
  try {
    dispatch(handleLoading());
    const { data } = await sendRequest(`/lms/lesson/${lessonId}/`);
    dispatch({
      type: GET_LESSON,
      payload: data,
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: SET_LESSON_ERRORS,
      payload: error.response,
    });
  }
};

export const addSingleLesson = (lesson, courseId, toast) => async (
  dispatch,
) => {
  try {
    dispatch({
      type: RESET_PROGRESS,
    });
    const formData = new FormData();
    Object.keys(lesson).map((key) => formData.append(key, lesson[key]));

    const { data } = await sendRequest(
      '/lms/lesson/',
      formData,
      'POST',
      undefined,
      dispatch,
    );
    dispatch({
      type: RESET_PROGRESS,
    });

    toast.success('Lesson Added, Successfully');
    dispatch({
      type: ADD_SINGLE_LESSON,
      payload: data,
    });
    dispatch(getCourse(courseId, 'INSTRUCTOR'));
    return;
  } catch ({ response }) {
    dispatch(handleFailedUpload());
    if (response) {
      return response.data;
    }
  }
};

export const toggleLessonCompleted = (data, checked) => async (dispatch) => {
  let response;
  try {
    if (checked) {
      response = await sendRequest('/lms/course/progress/add/', data, 'POST');
    } else {
      response = await sendRequest(
        '/lms/course/progress/remove/',
        data,
        'POST',
      );
    }
    dispatch(updateCourseAfterMarking(data.course, response.data));
  } catch (error) {
    dispatch({
      type: SET_LESSON_ERRORS,
      payload: error?.response?.data,
    });
  }
};

export const updateLesson = (lesson, courseId, lessonId, toast) => async (
  dispatch,
) => {
  try {
    const formData = new FormData();
    Object.keys(lesson).map((key) => formData.append(key, lesson[key]));

    const { data } = await sendRequest(
      `/lms/lesson/${lessonId}/`,
      formData,
      'PATCH',
    );

    dispatch({
      type: UPDATE_LESSON,
      payload: data,
    });
    toast.success('Lesson Updated, Successfully');
    dispatch(getCourse(courseId, 'INSTRUCTOR'));
    return;
  } catch ({ response }) {
    if (response) {
      return response.data;
    }
  }
};

export const deleteLesson = (lessonId, courseid, toast) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/lms/lesson/${lessonId}/`,
      null,
      'DELETE',
    );
    dispatch({
      type: DELETE_LESSON,
      payload: data,
    });

    toast.success('Deleted successfully');
    dispatch(getCourse(courseid, 'INSTRUCTOR'));
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};
