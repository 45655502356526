import {
  GET_ERRORS,
  LOGOUT,
  SET_CURRENT_USER,
  GET_CURRENT_USER,
  HANDLE_LOADING,
  DOMAINS,
  SET_DOMAIN,
  SIGNUP,
  VERIFY,
  SET_COMPANY,
  SET_ADMIN_DASHBOARD_INFO,
  SET_CURRENT_VIEW,
  GET_CURRENT_PROFILE,
  // GET_All_USERS,
} from 'actions/types';
import {
  GET_USERS,
  ADD_USER,
  DELETE_USER,
  GET_SINGLE_USER,
} from 'actions/types/userTypes';
import isEmpty from 'helpers/isEmpty';

const initialState = {
  loading: false,
  isAuthenticated: false,
  signup: null,
  verify: null,
  courseview: false,
  user: {},
  searchResults: {},
  profile:{},
  singleUser: null,
  users: { results: [] },
  updated: false,
  company: {},
  loaded: false,
  errorMessage: {},
  domains: [],
  domain: {},
  adminInfo: {
    totalUsers: 0,
    totalCourses: 0,
    active_courses: [],
    purchased_courses: [],
    subscription_remaining: 0,
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: !isEmpty(action.payload),
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
      case GET_CURRENT_PROFILE:
        return{
          ...state,
          profile:action.payload
        }
    case SIGNUP:
      return {
        ...state,
        signup: action.payload,
      };
    case VERIFY:
      return {
        ...state,
        verify: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case "USER_SEARCH":
      return {
        ...state,
        searchResults: action.payload,
      };
    case GET_SINGLE_USER:
      return {
        ...state,
        loading: false,
        singleUser: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case DOMAINS:
      return {
        ...state,
        domains: action.payload,
      };
    case SET_DOMAIN:
      return {
        ...state,
        domain: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SET_COMPANY:
      return {
        ...state,
        loading: false,
        company: action.payload,
        loaded: true,
      };
    case ADD_USER:
      return {
        ...state,
        users: {
          ...state.users,
          results: [action.payload, ...state.users.results],
        },
      };

    case DELETE_USER:
      return {
        ...state,
        updated: !state.updated,
        users: {
          ...state.users,
          results: [...state.users.results].filter(
            (user) => user.id !== action.payload,
          ),
        },
      };
    case SET_ADMIN_DASHBOARD_INFO:
      return {
        ...state,
        adminInfo: {
          totalUsers: action.payload.users,
          totalCourses: action.payload.courses,
          active_courses: action.payload.active_courses,
          purchased_courses: action.payload.purchased_courses,
          subscription_remaining: action.payload.subscription_remaining,
        },
      };
    case SET_CURRENT_VIEW:
      return {
        ...state,
        loading: false,
        courseview: !action.payload,
      };
    
    default:
      return state;
  }
};

export default authReducer;
