import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import learningGroupReducer from './learningGroupReducer';
import moduleReducer from 'reducers/moduleReducer';
import lessonReducer from 'reducers/lessonReducer';
import courseReducer from './courseReducer';
import userReducer from './userReducer';
import loadingReducer from 'reducers/loadingReducer';
import tabReducer from './tabReducer';
import companyReducer from './companyReducer';
import roleReducer from 'reducers/roleReducer';
import settings from '../reducers/settingsReducer';
import forums from '../reducers/forumReducer';
import comments from '../reducers/commentReducer';
import subscription from './subscriptionReducer';
import questionReducer from '../reducers/questionReducer';
import messageReducer from '../reducers/messagesReducer';
import reportReducer from '../reducers/reportReducer';
import leaderboardReducer from '../reducers/leaderboardReducer';
import fileReducer from 'reducers/fileReducer';
import assignmentReducer from 'reducers/assignmentReducer';
import projectReducer from 'reducers/projectReducer';
import assessmentReducer from 'reducers/assessmentReducer';
import notesReducer from 'reducers/notesReducer';
import announcementsReducer from 'reducers/announcementsReducer';
import eventsReducer from 'reducers/eventsReducer';
import progressReducer from 'reducers/progressReducer';
import notification from 'reducers/notificationReducer'
import courseEnrollmentReducer from 'reducers/courseEnrollmentReducer';
import customizationReducer from 'reducers/customizationReducer';
import coursePaymentReducer from 'reducers/coursePaymentReducer';

const rootReducer = combineReducers({
  //reducers come in here
  customization: customizationReducer,
  events: eventsReducer,
  company: companyReducer,
  auth: authReducer,
  error: errorReducer,
  learningGroup: learningGroupReducer,
  course: courseReducer,
  module: moduleReducer,
  lessons: lessonReducer,
  users: userReducer,
  loading: loadingReducer,
  tab: tabReducer,
  activeRole: roleReducer,
  settings,
  forums,
  comments,
  subscription,
  question: questionReducer,
  message: messageReducer,
  report: reportReducer,
  leaderboard: leaderboardReducer,
  file: fileReducer,
  assignment: assignmentReducer,
  project:projectReducer,
  assessment:assessmentReducer,
  notes: notesReducer,
  announcements:announcementsReducer,
  progress: progressReducer,
  notification,
  courseEnrollment: courseEnrollmentReducer,
  coursePayment: coursePaymentReducer
 });

export default rootReducer;
