import {
    ADD_TOPIC_NOTES, GET_TOPIC_NOTES
  } from 'actions/types/notesTypes';
  
  const initialState = {
    note: {},
    notes: {},
  };
  
  const notesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_TOPIC_NOTES:
        return {
          ...state,
          note: action.payload
        };
        case GET_TOPIC_NOTES:
        return {
          ...state,
          notes: action.payload
        };
      default:
        return state;
    }
  };
  
  export default notesReducer;
  