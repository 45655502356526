import { sendRequest } from 'config/api';
import jwt_decode from 'jwt-decode';
import isEmpty from 'helpers/isEmpty';
import { getSettings } from 'actions/customizationAction';
import { toast } from 'react-toastify';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  GET_CURRENT_USER,
  HANDLE_LOADING,
  DOMAINS,
  GET_USERS,
  SET_COMPANY,
  SET_DOMAIN,
  SIGNUP,
  VERIFY,
  SET_ACTIVE_ROLE,
  SET_CURRENT_VIEW,
  GET_CURRENT_PROFILE,
} from './types';

import setAuthToken from 'helpers/setAuthToken';

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setUpdatedUser = (decoded) => async (dispatch) => {
  let updatedProfile = decoded;
  if (!isEmpty(decoded)) {
    const { user_id } = decoded;
    const { data } = await sendRequest(`/auth/users/${user_id}/`);
    updatedProfile = {
      ...decoded,
      ...data
    };
  }

  dispatch({
    type: SET_CURRENT_USER,
    payload: updatedProfile,
  });
};

export const getCurrentUser = (userId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/auth/users/${userId}/`);
    dispatch({
      type: GET_CURRENT_USER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/auth/users/${userId}/`, '', 'DELETE');
    dispatch({
      type: GET_CURRENT_PROFILE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const getUserProfile = (userId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/auth/users/${userId}/`);
    dispatch({
      type: GET_CURRENT_PROFILE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const register = (userData) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      '/auth/users/register/',
      userData,
      'POST',
    );
    dispatch({
      type: SIGNUP,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const verification = (token) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      '/auth/users/register/verification/',
      token,
      'POST',
    );
    dispatch({
      type: SIGNUP,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const resendVerification = (userData) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      '/auth/users/register/verification/resend/',
      userData,
      'POST',
    );
    dispatch({
      type: VERIFY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

const setDefaultRole = (roles) => async (dispatch) => {
  if (roles.includes('ADMIN')) {
    localStorage.setItem('activeRole', 'ADMIN');
    dispatch({
      type: SET_ACTIVE_ROLE,
      payload: 'ADMIN',
    });
  }
};

export const login = (signInData) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });

  try {
    const response = await sendRequest('/auth/login/', signInData, 'POST');
    const { access, refresh } = response.data;
    localStorage.setItem('refresh', refresh);
    localStorage.setItem('access', access);
    const decoded_user = await jwt_decode(access);
    dispatch(setDefaultRole(decoded_user.roles));
    dispatch(setCurrentUser(decoded_user));
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

// Log user out
export const logout = () => (dispatch) => {
  // Remove the token from localStorage
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  localStorage.removeItem('activeRole');
  // Remove the auth header from future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.href = '/signin';
};

// Get domain list
export const getDomains = () => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/org/company/domains/`);
    dispatch({
      type: DOMAINS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

// Get current company
export const getCompany = (domain) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/org/company/data/?domain=${domain}`);

    dispatch({
      type: SET_COMPANY,
      payload: data,
    });
    document.getElementById('browser-tab-title').textContent = data.name;

    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = data?.settings?.company_banner;
    document.getElementsByTagName('head')[0].appendChild(link);

    dispatch(getSettings(data?.customizations));
    if (data?.customizations && data?.customizations?.brand_color?.length > 0) {
      dispatch(getSettings(data?.customizations));
    } else {
      if (localStorage.access) {
        dispatch(logout());
      }
    }
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
    dispatch({
      type: SET_COMPANY,
      payload: {},
    });
  }
};

// // Get Users
// export const getUsers = (pageNumber = 2, pageSize = 5) => async (dispatch) => {
//   try {
//     const { data } = await sendRequest(`/auth/users/?page=${pageNumber}`);
//     // `/auth/users/?page=${pageNumber}&page_size=${pageSize}`,

//     dispatch({
//       type: GET_USERS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: GET_ERRORS,
//       payload: error,
//     });
//   }
// };

export const getUsers = (link, pageNumber = 1, pageSize = 12, role) => async (
  dispatch,
) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      link ||
        `/auth/users/?page=${pageNumber}&page_size=${pageSize}${
          role ? '&roles=' + role : ''
        }`,
    );
    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const getUsersSearch = (link) => async (dispatch) => {
  try {
    const { data } = await sendRequest(link);
    // `/auth/users/?page=${pageNumber}&page_size=${pageSize}`,

    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

export const searchUser = (searchTerm, link=null) => async (dispatch) => {
  try {
    const { data } = await sendRequest(link || `/auth/users/?search=${ searchTerm }`);
    dispatch({
      type: "USER_SEARCH",
      payload: data,
    });
    return data;
  } catch (error) {
    toast.error('Error searching for user');
  }
};
//Get Users in a course
export const getStudentsInCourse = (courseId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/lms/course/${courseId}/users/`);
    dispatch({
      type: GET_USERS, //rather than create a different action type...why not use this?
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

// set selected domain
export const setDomain = (domain) => (dispatch) => {
  dispatch({
    type: SET_DOMAIN,
    payload: { domain },
  });
};

export const resetPassword = (userData) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      '/auth/users/reset_password/',
      userData,
      'POST',
    );
    dispatch({
      type: SIGNUP,
      payload: data,
    });
    toast.success('We sent a reset password link to your inbox');
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
    if (error.response.data) {
      toast.error(error.response.data[0]);
      return;
    }
    toast.error('An error occured');
  }
};

export const passwordChange = (userData) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      '/auth/users/reset_password/change/',
      userData,
      'POST',
    );
    dispatch({
      type: SIGNUP,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const storeChangeView = (change) => {
  return {
    type: SET_CURRENT_VIEW,
    payload: change,
  };
};

export const changePassword = (passwordDetails) => async (dispatch) => {
  try {
    await sendRequest('/auth/users/change_password/', passwordDetails, 'POST');
    return;
  } catch ({ response, message }) {
    return response.data || message;
  }
};
