import axios from 'axios';
// const url = "http://localhost:8000/api/v1"

export const baseUrl =
  process.env.REACT_APP_BASE_URL || 'https://dev.api.peerlesslms.com/api/v1';

// This is the right one going forward
export const sendRequest = (
  endpoint,
  data = {},
  method = 'GET',
  contentType = 'application/json',
  dispatch,
) => {
  try {
    return axios({
      url: `${baseUrl}${endpoint}`,
      method: method,
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-type': contentType,
        Authorization: localStorage.getItem('access')
          ? `Bearer ${localStorage.getItem('access')}`
          : '',
      },
      onUploadProgress: progressEvent => {
        const uploadedPercent =  parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        )
        if(dispatch) dispatch({type:'IS_UPLOADING', payload:{percent: uploadedPercent}})
      }
    });
  } catch (error) {
    console.log(error.message);
  }
};
