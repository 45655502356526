import { GET_ERRORS, HANDLE_LOADING } from 'actions/types';
import {
  GET_ALLUSERS,
  ADD_USER,
  SEARCH_USER,
  GET_All_USERS,
  GET_SINGLE_USER,
  BUTTON_DISABLED,
} from 'actions/types/userTypes';

const initialState = {
  loading: false,
  button_disabled: false,
  singleUser: null,
  errorMessages: '',
  user: {},
  users: {},
  allstudentUsers: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALLUSERS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case SEARCH_USER:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case GET_All_USERS:
      return {
        ...state,
        allstudentUsers: action.payload,
      };
    case GET_SINGLE_USER:
      return {
        ...state,
        singleUser: action.payload,
      };
      case  BUTTON_DISABLED:
        return {
          ...state,
          button_disabled: !state.button_disabled,
        };
    default:
      return state;
  }
};

export default userReducer;
