import { detect } from 'detect-browser';

export const handleUnssuportedBrowsers = () => {
  const browser = detect();

  const supportedBrowsers = ['chrome', 'firefox', 'safari', 'ios'];

  if (!supportedBrowsers.includes(browser.name)) {
    //this could be refactored to use errorboundaries...this works for now
    //window.history.pushState({}, '', '/unsupported-browser');
    //return;
  }
};
