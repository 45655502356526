import {
    GET_FORUMS,
    GET_FORUM_TOPICS,
    ADD_FORUM_TOPIC,
    GET_TOPIC,
    SEARCH_FORUMS
  } from 'actions/types/forumTypes';
  
  const initialState = {
    forums: {},
    topics: {},
    singleTopic: {},
  };
  
  const forumReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_FORUMS:
        return {
          ...state,
          forums: action.payload,
        };
        case SEARCH_FORUMS:
        return {
          ...state,
          forums: action.payload,
        };
        case GET_FORUM_TOPICS:
        return {
          ...state,
          topics: action.payload,
        };

        case GET_TOPIC:
          return {
            ...state,
            singleTopic: action.payload,
          };

        case ADD_FORUM_TOPIC:
        return {
          ...state,
          topics: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default forumReducer;
  