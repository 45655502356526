export const SEND_ASSIGNMENT = 'SEND_ASSIGNMENT';
export const   GET_ASSIGNMENT = 'GET_ASSIGNMENT';
export const  SET_ASSIGNMENT_ERRORS = ' SET_ASSIGNMENT_ERRORS';
export const  GET_ASSIGNMENT_RESULT = 'GET_ASSIGNMENT_RESULT';
export const  GET_ASSIGNMENT_STATUS = ' GET_ASSIGNMENT_STATUS';

export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT'
export const UPDATE_ASSIGMENT = 'UPDATE_ASSIGMENT'
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT'

export const ASSIGNMENT_QUESTONS_RESULT = 'ASSIGNMENT_QUESTONS_RESULT'

