import { SAVE_SETTINGS, GET_SETTINGS } from 'actions/types/customizationTypes';

import {
  hexToRgb,
  isLightColor,
} from 'helpers/utils';

//so users can see their changes immediately, when the browser first loads the site
//the customisation configurations are stored on the local storage
//on subsequent loads the local storage is used as the source of truth
//however, on every load this data is still refreshed, and compared with local storage
//if there are changes, the changes are made to the locally stored data
//therefore, at the component level, local storage is the source of truth
//while on the app level, the server is the source of truth.
const cachedStore =
  localStorage &&
  JSON.parse(localStorage.getItem('customisationConfig'));

//represent the state of the customization form;
//values are initialised from the local storage data if present

const initialState = {
  selectedBrandColor: cachedStore?.selectedBrandColor  ?? {
    color: '#6873EF',
    active: true,
  },
  brandColors: (cachedStore?.brandColors) ?? [{ color: '#6873EF', active: true }],
  selectedNavColor: cachedStore?.selectedNavColor ?? {
    color: '#FFFFFF',
    active: true,
  },
  navColors: cachedStore?.navColors ?? [
    { color: '#FFFFFF', active: true },
    { color: '#2C2C2C', active: false },
  ],
  selectedNavBackground: cachedStore?.selectedNavBackground ?? {
    color: '#F7F7F7',
    active: true,
  },
  navBackgroundColors: cachedStore?.navBackgroundColors ?? [
    { color: '#F7F7F7', active: true },
  ],
  bannerText: cachedStore?.bannerText ?? 'Expand your knowledge',
  companyBanner: cachedStore?.companyBanner || [
    {
      image:
        'https://d7o82nw6tjog0.cloudfront.net/media/company/banner/user/templates/images/banner_DMujrwN.png',
      file: null,
      active: true,
    },
  ],
  editMode: { status: false },
  bannerDescription:
    cachedStore?.bannerDescription ??
    'Gain valuable knowledge from expertly curated courses.',
  bannerDetails: cachedStore?.bannerDetails ?? [],
};

const customizationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_SETTINGS:
      
      
      return {
        ...payload,
      };
    case GET_SETTINGS:
      
      
      const newData = {
        ...state,
        id: payload.id,
        brandColors: payload.brand_color ?? [],
        navColors: payload.navigation_bar ?? [],
        navBackgroundColors: payload.background ?? [],
        companyBanner: payload.company_banner ?? [],
        bannerDetails: payload.company_banner ?? [],
        bannerDescription: payload.banner_description ?? '',
        bannerText: payload.banner_title ?? '',       
      };

      let root = document.documentElement;
      root.style.setProperty(
        '--nav-active',
        newData.brandColors.find((each) => each.active === true)?.color ?? '#6873ef',
      );
      root.style.setProperty(
        '--nav-bg',
        newData.navColors.find((each) => each.active === true)?.color ?? '#ffffff',
      );
      root.style.setProperty(
        '--body-bg',
        newData.navBackgroundColors.find((each) => each.active === true)?.color,
      );

      if (newData?.navColors.find((each) => each.active === true)) {
        const selectedColor = newData?.navColors.find(
          (each) => each.active === true,
        )?.color;

        const navRgb = hexToRgb(selectedColor);

        if (isLightColor(navRgb)) {
          root.style.setProperty('--stroke-color', '#4f4f4f');
        } else {
          root.style.setProperty('--stroke-color', '#FFFFFF');
        }
      }

      if (localStorage) {
        localStorage.setItem('customisationConfig', JSON.stringify(newData));
      }

      return newData;
    default:
      return state;
  }
};

export default customizationReducer;
