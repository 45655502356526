/**
 * This is a function.
 *
 * @param {string} endDate - A date string
 * @return {boolean}
 *
 */
const isDateExpired = (endDate) => {
  const todayDate = new Date();
  if (todayDate <= new Date(endDate)) {
    return false;
  } else {
    return true;
  }
};

export default isDateExpired;
