import {
  GET_FILE_STORAGE_ERROR,
  GET_FILE_STORAGE,
  GET_FILE_ENTRIES,
  GET_FILE_ENTRIES_ERROR,
  CREATE_FOLDER_ERROR,
  DELETE_FILE_ENTRY_ERROR,
  SEARCH_FILE_ERROR,
  SEARCH_FILE,
  GET_DELETED_FILES_ERROR,
  GET_DELETED_FILES,
  GET_SHARED_FILES,
  GET_SHARED_FILES_ERROR,
  UPLOAD_FILE_ERROR,
  RESTORE_FILE_ERROR,
  GET_FOLDER_ENTRIES_ERROR,
  GET_FOLDER_ENTRIES,
  SHARE_FILE_ERROR,
  UNSHARE_FILE_ERROR,
  SEARCH_SHARE_FILE_USERS_ERROR,
  SEARCH_SHARE_FILE_USERS,
} from 'actions/types/fileTypes';

const initialState = {
  storage: null,
  recentFiles: null,
  error: null,
  entries: null,
  searchResults: null,
  deletedFiles: null,
  sharedFiles: null,
  folderName: '',
  sharedFileUsers: null,
};

const fileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FILE_STORAGE:
    case GET_FILE_STORAGE_ERROR:
    case GET_FILE_ENTRIES:
    case GET_FILE_ENTRIES_ERROR:
    case CREATE_FOLDER_ERROR:
    case DELETE_FILE_ENTRY_ERROR:
    case SEARCH_FILE:
    case SEARCH_FILE_ERROR:
    case GET_DELETED_FILES:
    case GET_DELETED_FILES_ERROR:
    case GET_SHARED_FILES_ERROR:
    case GET_SHARED_FILES:
    case UPLOAD_FILE_ERROR:
    case RESTORE_FILE_ERROR:
    case GET_FOLDER_ENTRIES:
    case GET_FOLDER_ENTRIES_ERROR:
    case SHARE_FILE_ERROR:
    case UNSHARE_FILE_ERROR:
    case SEARCH_SHARE_FILE_USERS:
    case SEARCH_SHARE_FILE_USERS_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default fileReducer;
