import {
  FETCH_NOTIFICATION,
  NOTIFICATION_RECEIVED,
  CLEAR_NOTIFICATION,
  MARK_NOTIFICATION_AS_READ,
} from 'actions/types/notificationTypes';

const initialState = {
  loading: false,
  notifications: {},
  notificationReceived: false,
};

const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_NOTIFICATION:
      return {
        ...state,
        notifications: payload,
      };
    case NOTIFICATION_RECEIVED:
      return {
        ...state,
        notificationReceived: !state.notificationReceived,
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: {},
      };
    case MARK_NOTIFICATION_AS_READ:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          results: state.notifications.results.map((result) => {
            if (result.id === payload) {
              result.is_read = true;
            }
            return result;
          }),
        },
      };
    default:
      return state;
  }
};

export default notificationReducer;
