import firebase from 'firebase';
import { notificationReceived, sendDeviceToken } from 'actions';

const config = {
  apiKey: 'AIzaSyBthq2JTxeUCYLX2_9ybzshSJlO1aURM0w',
  authDomain: 'ireporter-1545139149444.firebaseapp.com',
  databaseURL: 'https://ireporter-1545139149444.firebaseio.com',
  projectId: 'ireporter-1545139149444',
  storageBucket: 'ireporter-1545139149444.appspot.com',
  messagingSenderId: '1010672167145',
  appId: '1:1010672167145:web:98de7244227318ffb56878',
  measurementId: 'G-L264FSERZR',
};

firebase.initializeApp(config);

export const requestPermissionToUseFirebase = async (store) => {
  try {
    const messaging = firebase.messaging.isSupported()
      ? firebase.messaging()
      : null;
    if (messaging) {
      await messaging.requestPermission(); //this has been deprecated though...get token handles it..but i would leave it here for now...
      const token = await messaging.getToken();

      store.dispatch(sendDeviceToken(token));

      messaging.onMessage((payload) => {
        store.dispatch(notificationReceived(payload));
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export default firebase;
