import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import {  useLocation} from "react-router";


const Footer = () => {
  
  let id = useLocation();

  let t = '';

  const array = [
    '/forgotpassword',
    '/resetpassword',
    '/signup',
    '/signin',
    '/verify',
    '/reset'
  ];
  const value = id.pathname;
  if (value.lastIndexOf('/') === 0) {
    t = value;
  } else if (value.lastIndexOf('/') !== 0) {
    t = value.substr(0, value.lastIndexOf('/'));
  }
  const isInArray = array.includes(t);
  if (isInArray) {
    return '';
  }

  
  return (
    <footer className='container d-flex flex-column align-items-center align-items-sm-stretch flex-sm-row justify-content-between footer'>
      <div>
        <p>
          Copyright © {new Date().getFullYear()} Zone Tech Park. All rights
          reserved.
        </p>
      </div>
      <div>
        <Link className='pr-3' to='#'>
          Privacy Policy
        </Link>
        <Link className='pr-3' to='#'>
          Terms of Use
        </Link>
        <Link to='#'>Contact</Link>
      </div>
    </footer>
  );
};

export default Footer;
