import { sendRequest } from 'config/api';
import {
  FETCH_NOTIFICATION,
  NOTIFICATION_RECEIVED,
  DEVICE_TOKEN_SENT,
  CLEAR_NOTIFICATION,
  MARK_NOTIFICATION_AS_READ,
} from './types/notificationTypes';
import { toast } from 'react-toastify';

export const sendDeviceToken = (deviceToken) => async (dispatch) => {
  const fcmData = {
    registration_id: deviceToken,
    type: 'web',
  };

  try {
    const { data } = await sendRequest('/auth/device/fcm/', fcmData, 'POST');
    dispatch({
      type: DEVICE_TOKEN_SENT,
      payload: data,
    });

    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const notificationReceived = (notificationDetails) => (dispatch) => {
  try {
    dispatch({
      type: NOTIFICATION_RECEIVED,
      payload: notificationDetails,
    });

    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
  //
};

export const fetchNotifications = () => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/notification/me/`);
    dispatch({
      type: FETCH_NOTIFICATION,
      payload: data,
    });

    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};

export const clearNotifications = () => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/notification/me/`,
      null,
      'DELETE'
    );
    dispatch({
      type: CLEAR_NOTIFICATION,
      payload: data,
    });

    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response?.data));
    return response?.data || message;
  }
};

export const markNotificationAsRead = (notificationId, payload) => async (
  dispatch,
) => {
  try {
    await sendRequest(`/notification/${notificationId}/`, payload, 'PATCH');
    dispatch({
      type: MARK_NOTIFICATION_AS_READ,
      payload: notificationId,
    });

    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response?.data));
    return response?.data || message;
  }
};
