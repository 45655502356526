import { SET_ACTIVE_ROLE , SET_LOADER} from 'actions/types';

export const setActiveRole = (userRole) => (dispatch) => {
  localStorage.setItem('activeRole', userRole);
  return dispatch({
    type: SET_ACTIVE_ROLE,
    payload: userRole,
  });
};

export const setLoader = (status) => (dispatch) => {
 
  return dispatch({
    type: SET_LOADER,
    payload: status,
  });
};
