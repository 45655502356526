import { sendRequest } from 'config/api';
import { setUpdatedUser } from './authActions';
import { getSettings } from './customizationAction';
import {
  GET_ERRORS,
  GET_ORG_DATA,
  COMPANY_DATA_UPDATE,
  GET_CURRENT_PROFILE,
} from './types';
import { toast } from 'react-toastify';

import { handleLoading } from './loadingAction';
import jwt_decode from 'jwt-decode';
const updateSatusAction = () => {
  return {
    type: COMPANY_DATA_UPDATE,
  };
};

export const getCompanyData = () => async (dispatch) => {
  dispatch(handleLoading());
  try {
    const response = await sendRequest('/org/company/current/');
    dispatch({
      type: GET_ORG_DATA,
      payload: response.data,
    });
    if (response?.data?.customizations) {
      dispatch(getSettings(response?.data?.customizations));
    }
    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

const upateCompanyBanner = (data) => {
  try {
    return sendRequest(
      '/org/company/update-settings/',
      data,
      'POST',
      'multipart/form-data',
    );
  } catch (error) {
    throw error;
  }
};

export const upateCompanyInfo =
  (data, banner, companyId, toast) => async (dispatch) => {
    dispatch(updateSatusAction());
    try {
      if (banner) await upateCompanyBanner(banner);
      await sendRequest(`/org/company/${companyId}/`, data, 'PATCH');
      toast.success('Info Successfully updated');
      dispatch(updateSatusAction());
    } catch (error) {
      dispatch(updateSatusAction());

      dispatch({
        type: GET_ERRORS,
        payload: error.message,
      });
    }
  };

export const updateEmailSettings = (data, toast) => async (dispatch) => {
  dispatch(updateSatusAction());
  try {
    await sendRequest('/org/company/update-settings/', data, 'POST');
    dispatch(getCompanyData())
    dispatch(updateSatusAction());
    if (toast) toast.success('Settings updated');
  } catch (error) {
    dispatch(updateSatusAction());

    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const updateNotificationSettings = (data, toast) => async (dispatch) => {
  dispatch(updateSatusAction());
  try {
    await sendRequest('/org/company/notification-settings/', data, 'PATCH');
    dispatch(updateSatusAction());
    if (toast) toast.success('Settings updated');
  } catch (error) {
    dispatch(updateSatusAction());

    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const getUserProfile = (userId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/auth/users/${userId}/`);
    dispatch({
      type: GET_CURRENT_PROFILE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const updateProfileDetails =
  (data, userId, toast) => async (dispatch) => {
    const decoded = jwt_decode(localStorage.access);
    try {
      await sendRequest(`/auth/users/${userId}/`, data, 'PATCH');
      dispatch(setUpdatedUser(decoded));
      dispatch(getCompanyData());
      dispatch(getUserProfile(userId));
      if (toast) toast.success('User details updated');
    } catch (error) {
      dispatch(updateSatusAction());
      dispatch({
        type: GET_ERRORS,
        payload: error.message,
      });
    }
  };

export const updateProfileImage = (data, userId, toast) => async (dispatch) => {
  dispatch(updateSatusAction());
  const decoded = jwt_decode(localStorage.access);
  try {
    await sendRequest(
      `/auth/users/${userId}/`,
      data,
      'PATCH',
      'multipart/form-data',
    );
    dispatch(updateSatusAction());
    dispatch(setUpdatedUser(decoded));
    // dispatch(getCurrentUser(userId));
    dispatch(getCompanyData());
    if (toast) toast.success('Profile image updated');
  } catch (error) {
    dispatch(updateSatusAction());
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const sendCertificateUpdate = (data, toast) => async (dispatch) => {
  dispatch(updateSatusAction());
  try {
    await sendRequest('/org/company/update-settings/', data, 'POST');
    dispatch(updateSatusAction());
    dispatch(getCompanyData());
    if (toast) toast.success('certifcate edited successfully');
  } catch (error) {
    dispatch(updateSatusAction());
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const resetCertificate = (data) => async () => {
  try {
    await sendRequest('/org/company/reset-certificate/', data, 'DELETE');
    toast.success('certifcate default template restored');
    return;
  } catch ({ response, message }) {
    toast.error(JSON.stringify(response.data || message));
    return JSON.stringify(response.data || message);
  }
};

export const saveCertificateFonts = (data) => async () => {
  try {
    await sendRequest('/org/company/certificate_font/', data, 'POST');
    return;
  } catch ({ response, message }) {
    return response.data || message;
  }
};
