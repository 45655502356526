import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isDateExpired from 'helpers/isDateExpired';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { activeRole } = useSelector((state) => state.activeRole);
  const { current_subscription } = useSelector(
    (state) => state.company.company,
  );

  const isTrialEnd =
    current_subscription?.trial_period_end &&
    isDateExpired(current_subscription?.trial_period_end);
  const isSubscriptionEnd =
    current_subscription?.subscription_end &&
    isDateExpired(current_subscription?.subscription_end);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          // check if the role is not an admin and the subscription has expired
          activeRole !== 'ADMIN' && isTrialEnd && isSubscriptionEnd ? (
            <Redirect to="/expired-subscription" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export default PrivateRoute;
