import React from 'react';
import logoUrl from "assets/pngFiles/Learnry_04.png";
import PeerlessMobileLogo from "assets/pngFiles/Learnry_04.png";


export default function Peerless(props) {
 
  return (
    <div className={props.class}>
      <img src={props.logo ? props.logo : logoUrl} alt="logo" />
    </div>
  );
}

export const MobileLogo = () => {
  return (
    <div>
      <img
        src={PeerlessMobileLogo}
        width="40px"
        alt="mobile logo"
        className="mobile-logo"
      />
    </div>
  );
};



