import React, { Fragment, useState, useEffect } from 'react';
import './NavigationBar.scss';
import './CourseDetailMediaNav.scss';
import { ReactComponent as MoreIcon } from 'assets/svgFiles/more.svg';
import { ReactComponent as RightArrowIcon } from 'assets/svgFiles/right-arrow.svg';
import { ReactComponent as HamburgerIcon } from 'assets/svgFiles/hamburger.svg';
import { ReactComponent as CloseIcon } from 'assets/svgFiles/close.svg';
import { BackIcon } from 'components/common/BackIcon/BackIcon';
import isDateExpired from 'helpers/isDateExpired';
import { NavLink, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Peerless from 'components/Logo/Peerless';
import { useWindowSize } from 'helpers/customHooks';
import { setActiveRole } from 'actions/roleAction';
import { logout, storeChangeView } from 'actions/authActions';
import RelevantNavItems from './renderRelevantNavItems';
import NotificationContent from './NotificationContent';

export const NavigationBar = () => {
  let history = useHistory();
  const { pathname } = useLocation();
  const checkActive = (match, location) => {
    if (!location) return false;
    let { pathname } = location;
    return pathname === '/';
  };
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname.includes(path);
  };

  const isHomeActive = (path) => {
    return location.pathname === '/';
  };

  const { course } = useSelector((state) => state.course);
  const { current_subscription } = useSelector(
    (state) => state.company.company,
  );
  const { user, courseview } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state.auth);
  const { activeRole } = useSelector((state) => state.activeRole);
  const { notificationReceived } = useSelector((state) => state.notification);
  const [showDropDown, setshowDropDown] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showMobileDropDown, setshowMobileDropDown] = useState(false);
  const [hamburgerControl, sethamburgerControl] = useState(courseview);
  const [width] = useWindowSize();
  let courseMedia = pathname.includes('course-details') && width < 998;

  const dispatch = useDispatch();

  const toggleDropDown = () => {
    setshowDropDown(!showDropDown);
  };

  const toggleMobileDropDown = () => {
    setshowMobileDropDown(!showMobileDropDown);
  };

  const changeRole = (role) => {
    dispatch(setActiveRole(role));
    history.push('/');
  };

  useEffect(() => {
    const closeDropDown = () => {
      if (showDropDown || showMobileDropDown) {
        setshowDropDown(false);
        setshowMobileDropDown(false);
      }
    };

    window.addEventListener('click', closeDropDown);

    return () => window.removeEventListener('click', closeDropDown);
  }, [showDropDown, showMobileDropDown, showNotification, user]);

  const logoutUser = () => {
    dispatch(logout());
    setshowDropDown(false);
  };

  const changeCourseDetailView = () => {
    sethamburgerControl(!hamburgerControl);
    dispatch(storeChangeView(hamburgerControl));
  };

  return user?.user_id ? (
    <section className="nav__bar_wrap">
      {!courseMedia ? (
        <div className="navigationbar container">
          <NavLink to="/" className="logo-item">
            <Peerless class="" logo={company.logo ? company.logo : ''} />
          </NavLink>

          <div className="nav-links">
            {activeRole !== 'ADMIN' &&
            isDateExpired(current_subscription?.trial_period_end) &&
            isDateExpired(current_subscription?.subscription_end) ? null : (
              <>
                <NavLink
                  to="/"
                  isActive={checkActive}
                  className="nav-links--item"
                  activeClassName="nav-links--item-active"
                >
                  <span className="inline-center">
                    <span>
                      <i
                        className={`icon-home nav-icons mr-1 ${
                          isHomeActive('/') ? 'icon-home-filled' : ''
                        }`}
                        aria-hidden="true"
                      />
                    </span>{' '}
                    <span>Home</span>
                  </span>
                </NavLink>
                <NavLink
                  to="/courses/"
                  className="nav-links--item"
                  activeClassName="nav-links--item-active"
                >
                  <span className="inline-center">
                    <span>
                      <i
                        className={`icon-courses nav-icons mr-1 ${
                          isActive('/courses/')
                            ? 'icon-courses-book-filled'
                            : ''
                        }`}
                        aria-hidden="true"
                      />
                    </span>{' '}
                    <span>Courses</span>
                  </span>
                </NavLink>
              </>
            )}

            {width > 650 ? (
              <RelevantNavItems
                activeRole={activeRole}
                trialEnd={current_subscription?.trial_period_end}
                subscriptionEnd={current_subscription?.subscription_end}
              />
            ) : (
              <>
                {showMobileDropDown && (
                  <div className="navigationbar__mobile-nav-options">
                    <RelevantNavItems activeRole={activeRole} />
                  </div>
                )}
                {activeRole !== 'ADMIN' &&
                isDateExpired(current_subscription?.trial_period_end) &&
                isDateExpired(current_subscription?.subscription_end) ? null : (
                  <div
                    className="nav-links--item"
                    onClick={() => toggleMobileDropDown()}
                  >
                    <span>
                      <i
                        className={`icon-more nav-icons mr-1 ${
                          isActive('/courses/')
                            ? 'icon-courses-book-filled'
                            : ''
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                    More
                  </div>
                )}
              </>
            )}
          </div>
          <div className="profile-item">
            <div
              role="button"
              className="dropdown-stack notification-sd"
              onClick={() => setShowNotification(true)}
            >
              {notificationReceived && (
                <i className="fa fa-circle top-bell" aria-hidden="true"></i>
              )}
              <span>
                <i
                  className={`icon-notification nav-icons mr-3`}
                  aria-hidden="true"
                />
              </span>
              {showNotification && (
                <NotificationContent
                  closeNoticationDropDown={() => setShowNotification(false)}
                />
              )}
            </div>

            <div className="avartar">
              {user?.image ? (
                <img src={user?.image} alt=""></img>
              ) : (
                user?.firstname && user.firstname[0]
              )}
            </div>
            <div className="dropdown-stack">
              <div className="dropdown" onClick={() => toggleDropDown()}>
                <span>{activeRole}</span>
                <div className="arrow-down"></div>
              </div>
              {showDropDown && (
                <div className="dropdown__content">
                  {activeRole !== 'ADMIN' &&
                  isDateExpired(current_subscription?.trial_period_end) &&
                  isDateExpired(
                    current_subscription?.subscription_end,
                  ) ? null : (
                    <>
                      <div className="dropdown__content--item border-bottom d-flex justify-content-between">
                        <Link
                          to="/settings/profile"
                          className="d-flex justify-content-between w-100"
                        >
                          {`${user?.lastname} ${user?.firstname}`}
                          <span className="profile">
                            Profile <RightArrowIcon />
                          </span>
                        </Link>
                      </div>
                      <Link to="/events">
                        <span className="dropdown__content--item">
                          Timeline
                        </span>
                      </Link>

                      <Link to="/messages">
                        <span className="dropdown__content--item">
                          Messages
                        </span>
                      </Link>

                      {activeRole !== 'STUDENT' && (
                        <Link to="/reports">
                          <span className="dropdown__content--item">
                            Reports
                          </span>
                        </Link>
                      )}

                      {activeRole === 'STUDENT' && (
                        <>
                          <Link to={`/course-enrollment/`}>
                            <span className="dropdown__content--item">
                              Course Enrollment Requests
                            </span>
                          </Link>

                          <Link to={`/reports/user/${user?.user_id}`}>
                            <span className="dropdown__content--item">
                              Reports
                            </span>
                          </Link>
                        </>
                      )}
                    </>
                  )}
                  {activeRole === 'ADMIN' && (
                    <>
                      <Link to={`/course-enrollment/`}>
                        <span className="dropdown__content--item">
                          Course Enrollment Requests{' '}
                        </span>
                      </Link>
                    </>
                  )}

                  <div
                    className="dropdown__content--item border-bottom"
                    onClick={logoutUser}
                  >
                    Sign out
                  </div>
                  {activeRole !== 'ADMIN' &&
                  isDateExpired(current_subscription?.trial_period_end) &&
                  isDateExpired(current_subscription?.subscription_end)
                    ? null
                    : user?.roles
                        ?.filter((role) => role !== activeRole)
                        .map((role) => (
                          <div
                            className="dropdown__content--item"
                            key={role}
                            onClick={() => changeRole(role)}
                          >
                            Switch to <span>{role}</span>
                          </div>
                        ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="navigationbar container course-detail-nav">
          <BackIcon />

          <div className="nav-links">
            <NavLink
              to="/"
              isActive={checkActive}
              className="nav-links--item"
              activeClassName="nav-links--item-active"
            >
              <span className="inline-center">
                <span>
                  <i
                    className={`icon-home nav-icons mr-1 ${
                      isActive('/courses/') ? 'icon-home-filled' : ''
                    }`}
                    aria-hidden="true"
                  />
                </span>{' '}
                <span>Courses</span>
              </span>
            </NavLink>
            <NavLink
              to="/courses"
              className="nav-links--item"
              activeClassName="nav-links--item-active"
            >
              <span className="inline-center">
                <span>
                  <i
                    className={`icon-courses nav-icons mr-1 ${
                      isActive('/courses') ? 'icon-courses-book-filled' : ''
                    }`}
                    aria-hidden="true"
                  />
                </span>{' '}
                <span>Courses</span>
              </span>
            </NavLink>

            {width > 998 ? (
              <RelevantNavItems activeRole={activeRole} />
            ) : (
              <>
                {showMobileDropDown && (
                  <div className="navigationbar__mobile-nav-options">
                    <RelevantNavItems activeRole={activeRole} />
                  </div>
                )}
                <div
                  className="nav-links--item"
                  onClick={() => toggleMobileDropDown()}
                >
                  <MoreIcon className="nav-links--item"></MoreIcon>
                  More
                </div>
                <div className="profile-item">
                  <div className="avartar">
                    {user?.image ? (
                      <img src={user?.image} alt=""></img>
                    ) : (
                      user?.firstname && user.firstname[0]
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="profile-item top-profile-item">
            <span className="name_course_title">{course?.title}</span>
            {!courseview ? (
              <HamburgerIcon
                onClick={changeCourseDetailView}
                className="mr-3 hamburger"
              ></HamburgerIcon>
            ) : (
              <CloseIcon
                onClick={changeCourseDetailView}
                className="mr-3 hamburger"
              ></CloseIcon>
            )}

            <div className="dropdown-stack">
              {showDropDown && (
                <div className="dropdown__content">
                  <div className="dropdown__content--item border-bottom d-flex justify-content-between">
                    {`${user?.lastname} ${user?.firstname}`}
                    <span className="profile">
                      Profile <RightArrowIcon />
                    </span>
                  </div>

                  <Link to="/messages">
                    <span className="dropdown__content--item">Messages</span>
                  </Link>
                  {/*
                     <Link to="/forum">
                       <span className="dropdown__content--item">Forum</span>
                     </Link>
                     */}

                  <Link to="/reports">
                    <span className="dropdown__content--item">Reports</span>
                  </Link>
                  <div
                    className="dropdown__content--item border-bottom"
                    onClick={logoutUser}
                  >
                    Sign out
                  </div>
                  {activeRole !== 'ADMIN' &&
                  isDateExpired(current_subscription?.trial_period_end) &&
                  isDateExpired(current_subscription?.subscription_end)
                    ? null
                    : user?.roles?.map((role) => (
                        <div
                          className="dropdown__content--item"
                          key={role}
                          onClick={() => changeRole(role)}
                        >
                          Switch to <span>{role}</span>
                        </div>
                      ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  ) : (
    <Fragment></Fragment>
  );
};
