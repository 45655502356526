import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_ERRORS_FOR_STARTED_QUESTIONS,
} from 'actions/types';

const initialState = {
  errorMessages: '',
  started: '',
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errorMessages: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        errorMessages: null,
      };
    case GET_ERRORS_FOR_STARTED_QUESTIONS:
      return {
        ...state,
        started: action.payload,
      };
    default:
      return state;
  }
};

export default errorReducer;
