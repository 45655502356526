/**
 * This imports the intersection observer pollyfill for browsers that may not support it
 *
 * Only older versions of Safari are known not to support intersection observer
 */
export async function initialiseObserver() {
  if (!('IntersectionObserver' in window)) {
    await import('intersection-observer');
  }
}
