import {
  ADD_MODULE,
  GET_ERRORS,
  GET_MODULE,
} from 'actions/types/moduleTypes';

const initialState = {
  loading: false,
  created: false,
  modules: {},
  singleModule: {
    lessons: []
  },
  errorMessage: '',
};

const modules = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MODULE:
      return {
        ...state,
        created: true,
        modules: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case GET_MODULE:
      return {
        ...state,
        singleModule: action.payload
      }
    default:
      return state;
  }
};

export default modules;
