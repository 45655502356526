import {
  GET_ORG_DATA,
  COMPANY_DATA_UPDATE
} from 'actions/types';

const initialState = {
  companyData: {
    settings: {}
  },
  errorMessage: '',
  updating: false
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORG_DATA:
      return {
        ...state,
        companyData: action.payload,
      };
     case COMPANY_DATA_UPDATE:
     return{
       ...state,
       updating: !state.updating
     }
    default:
      return state;
  }
};

export default settingsReducer;
