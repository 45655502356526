import { useLayoutEffect, useState } from 'react';

/**
 * This is a custom hook that listens for window screen size updates
 */
export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}


export function removeTags(str) {
  if (str === null || str === '') return '';
  // str = str?.toString();
  return str?.replace(/(<([^>]+)>)/gi, '');
} 




 export function getAllLessons(module) {
    let totalnoOfLesson = [];
  
   for (const obj of module) {
     totalnoOfLesson.push(obj.lessons.length);
   }

  let total= totalnoOfLesson.reduce((a, b) => a + b, 0);
return total > 1 ? total + ' Lessons' : total + ' Lesson'

 };

  export function getAllLesson(module) {
    let totalnoOfLesson = [];
  
   for (const obj of module) {
     totalnoOfLesson.push(obj.lessons.length);
   }

 return totalnoOfLesson.reduce((a, b) => a + b, 0);


 };


 export const formatPrice = (num) => {
  if (num){
  var p = parseInt(num).toFixed(2).split(".");
  return "₦" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
      return num + (num !== "-" && i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
}
return "₦"+ num;
 }