import {
  ASSESSMENT_QUESTONS_RESULT,
  GET_ERRORS_FOR_STARTED_QUESTIONS,
  GET_ASSESSSMENT_QUESTIONS,
  ADD_ASSESSSMENT_QUESTION,
} from 'actions/types/assessmentTypes';

const initialState = {
  allresult: [],
  status: '',
  errorMessage: null,
  loading: false,
  assessmentQuestions: [],
};

const assessmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ASSESSMENT_QUESTONS_RESULT:
      return {
        ...state,
        allresult: payload,
      };
    case GET_ERRORS_FOR_STARTED_QUESTIONS:
      return {
        ...state,
        errorMessage: payload,
      };
    case GET_ASSESSSMENT_QUESTIONS:
      return {
        ...state,
        assessmentQuestions: payload,
      };
    case ADD_ASSESSSMENT_QUESTION:
      return {
        ...state,
        assessmentQuestions: [
          ...state.assessmentQuestions,
          payload
        ]
      }

    default:
      return state;
  }
};

export default assessmentReducer;
