import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import isDateExpired from 'helpers/isDateExpired';

const RenderRelevantNavItems = ({ activeRole, trialEnd, subscriptionEnd }) => {
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname.includes(path);
  };
  return (
    <>
      {
        <>
          {activeRole === 'ADMIN' && (
            <NavLink
              to="/users"
              className="nav-links--item"
              activeClassName="nav-links--item-active"
            >
              <span className="inline-center">
                <span>
                  <i
                    className={`icon-user-stroke  nav-icons mr-1 ${
                      isActive('/users') ? 'icon-user-filled' : ''
                    }`}
                    aria-hidden="true"
                  />
                </span>{' '}
                <span>Users</span>
              </span>
            </NavLink>
          )}
          {activeRole &&
            (activeRole !== 'ADMIN' &&
            isDateExpired(trialEnd) &&
            isDateExpired(subscriptionEnd) ? null : (
              <NavLink
                to="/leaderboard"
                className="nav-links--item"
                activeClassName="nav-links--item-active"
              >
                <span className="inline-center">
                  <span>
                    <i
                      className={`icon-leaderboard-stroke path1 nav-icons mr-1 ${
                        isActive('/leaderboard')
                          ? 'icon-leaderboard_filled'
                          : ''
                      }`}
                      aria-hidden="true"
                    />
                  </span>{' '}
                  <span>Leaderboard</span>
                </span>
              </NavLink>
            ))}
          {activeRole !== 'ADMIN' &&
          isDateExpired(trialEnd) &&
          isDateExpired(subscriptionEnd) ? null : (
            <NavLink
              to="/forum"
              className="nav-links--item"
              activeClassName="nav-links--item-active"
            >
              <span className="inline-center">
                <span>
                  <i
                    className={`icon-forum nav-icons mr-1 ${
                      isActive('/forum') ? 'icon-forum-filled' : ''
                    }`}
                    aria-hidden="true"
                  />
                </span>{' '}
                <span>Forum</span>
              </span>
            </NavLink>
          )}

          {activeRole === 'ADMIN' && (
            <NavLink
              to="/settings"
              className="nav-links--item"
              activeClassName="nav-links--item-active"
            >
              <span className="inline-center">
                <span>
                  <i
                    className={`icon-settings--stroke nav-icons mr-1 ${
                      isActive('/settings') ? 'icon-settings-filled' : ''
                    }`}
                    aria-hidden="true"
                  />
                </span>{' '}
                <span>Settings</span>
              </span>
            </NavLink>
          )}
          {activeRole === 'ADMIN' ||
          (activeRole !== 'ADMIN' &&
            isDateExpired(trialEnd) &&
            isDateExpired(subscriptionEnd)) ? null : (
            <NavLink
              to="/files"
              className="nav-links--item"
              activeClassName="nav-links--item-active"
            >
              <span className="inline-center">
                <span>
                  <i
                    className={`icon-files-icon nav-icons mr-1 ${
                      isActive('/files') ? 'icon-files-filled' : ''
                    }`}
                    aria-hidden="true"
                  />
                </span>{' '}
                <span>Drive</span>
              </span>
            </NavLink>
          )}
        </>
      }
    </>
  );
};
export default RenderRelevantNavItems;
