import {
    ADD_TOPIC_COMMENT,
    GET_TOPIC_COMMENTS
  } from 'actions/types/commentTypes';
  
  const initialState = {
    comment: {},
    comments: {},
  };
  
  const commentReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_TOPIC_COMMENT:
        return {
          ...state,
          comment: action.payload
        };
        case GET_TOPIC_COMMENTS:
        return {
          ...state,
          comments: action.payload
        };
      default:
        return state;
    }
  };
  
  export default commentReducer;
  