import {
  GET_COURSE,
  GET_COURSES,
  // SEARCH_COURSES,
  SEARCH_COURSES_ALTERNATIVE,
  // CATEGORY_COURSES,
  GET_CATEGORIES,
  GET_MY_COURSES,
  GET_ASSINGED_COURSES,
  ADD_COURSE,
  GET_ACTIVE_COURSE_ID,
  ADD_COURSE_ERROR,
  GET_COURSE_SETTINGS,
  UPDATE_COURSE_SETTINGS,
  GET_STUDENTS_ASSIGNED_TO_COURSES,
  GET_COURSE_ASSIGNED_GROUPS,
  GET_COURSE_ASSIGNED_LOADING,
  SET_STUDENT_ASSIGNED_COURSES,
  SET_STUDENT_INPROGRESS_COURSES,
  GET_COURSE_STUDENT,
  UPDATE_COMPLETED_COURSE_LESSONS,
  GET_MY_COMPLETED_COURSES,
  GET_MY_AVAILABLE_COURSES,
  SEARCH_STUDENTS_ASSIGNED_TO_COURSES,
  TOGGLE_COURSE_PUBLISH_STATUS,
} from 'actions/types';

import { ADD_MODULE_TO_COURSE } from 'actions/types/moduleTypes';
import { ADD_SINGLE_LESSON } from 'actions/types/lessonTypes';
import {
  ADD_SINGLE_ASSESSMENT,
  REMOVE_SINGLE_ASSESSMENT,
} from 'actions/types/assessmentTypes';
// import ListItemStories from 'components/ListItem/ListItem.stories';
import { ADD_ANNOUNCEMENT } from 'actions/types/announcementsTypes';
import { ADD_ASSIGNMENT } from 'actions/types/assignmentTypes';

const initialState = {
  courses: {},
  mycourses: {},
  myCompletedcourses: {},
  myAvailablecourses: {},
  assigedCourses: {},
  categories: [],
  created: false,
  course: {
    modules: [],
    announcements: [],
    assignments: [],
    students: {
      results: [],
    },
    groups: {
      results: [],
    },
    loading: false,
  },
  error: null,
  adminPaginatedCourses: [],
  instructorPaginatedCourses: [],
  studentPaginatedCourses: [],
  studentCompletedPaginatedCourses: [],
  studentAvailablePaginatedCourses: [],
  paginatedCourses: [],
  studentCourses: {
    initial: true,
    inProgress: [],
    assigned: [],
  },
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          ...action.payload,
        },
      };
    case GET_COURSE_STUDENT:
      return {
        ...state,
        course: action.payload,
      };
    case UPDATE_COMPLETED_COURSE_LESSONS:
      return {
        ...state,
        course: {
          ...state.course,
          completed: action.payload,
        },
      };
    case GET_COURSES:
      if (action.payload.searchstatus) {
        state.adminPaginatedCourses = [];
      }
      return {
        loading: false,
        ...state,
        courses: action.payload.data,
        adminPaginatedCourses: [
          ...state.adminPaginatedCourses,
          ...action.payload.data.results,
        ],
      };
    case SEARCH_COURSES_ALTERNATIVE:
      return {
        ...state,
        courses: action.payload,
        adminPaginatedCourses: [
          ...state.adminPaginatedCourses,
          ...action.payload.results,
        ],
      };

    case GET_MY_COURSES:
      if (action.payload.searchstatus) {
        state.studentPaginatedCourses = [];
      }

      return {
        ...state,
        mycourses: action.payload.data,
        studentPaginatedCourses: [
          ...state.studentPaginatedCourses,
          ...action.payload.data.results,
        ],
      };

    case GET_MY_COMPLETED_COURSES:
      if (action.payload.searchstatus) {
        state.studentCompletedPaginatedCourses = [];
      }

      return {
        ...state,
        myCompletedcourses: action.payload.data,
        studentCompletedPaginatedCourses: [
          ...state.studentCompletedPaginatedCourses,
          ...action.payload.data.results,
        ],
      };

    case GET_MY_AVAILABLE_COURSES:
      if (action.payload.searchstatus) {
        state.studentAvailablePaginatedCourses = [];
      }

      return {
        ...state,
        myAvailablecourses: action.payload.data,
        studentAvailablePaginatedCourses: [
          ...state.studentAvailablePaginatedCourses,
          ...action.payload.data.results,
        ],
      };

    case GET_ASSINGED_COURSES:
      if (action.payload.searchstatus) {
        state.instructorPaginatedCourses = [];
      }
      return {
        ...state,
        assigedCourses: action.payload.data,
        instructorPaginatedCourses: [
          ...state.instructorPaginatedCourses,
          ...action.payload.data.results,
        ],
      };

    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case ADD_COURSE:
      return {
        ...state,
        course: {
          ...action.payload,
          modules: [],
          created: true,
        },
      };
    case GET_ACTIVE_COURSE_ID:
      return {
        ...state,
        courseId: action.payload,
      };
    case ADD_MODULE_TO_COURSE:
      return {
        ...state,
        course: {
          ...state.course,
          modules: [
            ...state.course.modules,
            {
              ...action.payload,
              lessons: [],
              assessments: [],
            },
          ],
        },
      };
    case ADD_SINGLE_LESSON:
      return {
        ...state,
        course: {
          ...state.course,
          modules: [
            ...state.course.modules.map((item) => {
              if (item.id === action.payload.module) {
                return {
                  ...item,
                  lessons: [...item.lessons, action.payload],
                };
              }
              return item;
            }),
          ],
        },
      };

    case ADD_SINGLE_ASSESSMENT:
      return {
        ...state,
        course: {
          ...state.course,
          modules: [
            ...state.course.modules.map((item) => {
              if (item.id === action.payload.modules[0]) {
                return {
                  ...item,
                  assessments: [...item.assessments, action.payload],
                };
              }
              return item;
            }),
          ],
        },
      };

    case REMOVE_SINGLE_ASSESSMENT:
      return {
        ...state,
        course: {
          ...state.course,
          modules: [
            ...state.course.modules.map((module) => {
              if (module.id === action.payload.moduleId) {
                const assessments = module.assessments.filter(
                  (assessment) => assessment.id !== action.payload.assessmentId,
                );
                return {
                  ...module,
                  assessments: assessments,
                };
              }
              return module;
            }),
          ],
        },
      };

    case ADD_COURSE_ERROR:
      return {
        ...state,
        error: action.errorMessage,
      };

    case GET_COURSE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case UPDATE_COURSE_SETTINGS:
      return {
        ...state,
        course: {
          ...state.course,
          settings: action.payload.settings,
          published: action.payload.published,
        },
      };
    case TOGGLE_COURSE_PUBLISH_STATUS:
      return {
        ...state,
        course: {
          ...state.course,
          published: action.payload.published,
        },
      };
    case GET_STUDENTS_ASSIGNED_TO_COURSES:
      return {
        ...state,
        course: {
          ...state.course,
          students: action.payload,
          loading: false,
        },
      };
    case SEARCH_STUDENTS_ASSIGNED_TO_COURSES:
      return {
        ...state,
        course: {
          ...state.course,
          students: action.payload,
          loading: false,
        },
      };
    case GET_COURSE_ASSIGNED_GROUPS:
      return {
        ...state,
        course: {
          ...state.course,
          groups: action.payload,
          loading: false,
        },
      };
    case GET_COURSE_ASSIGNED_LOADING:
      return {
        ...state,
        course: {
          ...state.course,
          loading: !state.course.loading,
        },
      };

    case SET_STUDENT_ASSIGNED_COURSES:
      return {
        ...state,
        studentCourses: {
          ...state.studentCourses,
          initial: false,
          assigned: action.payload,
        },
      };

    case SET_STUDENT_INPROGRESS_COURSES:
      return {
        ...state,
        studentCourses: {
          ...state.studentCourses,
          initial: false,
          inProgress: action.payload,
        },
      };
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        course: {
          ...state.course,
          announcements: [action.payload, ...state.course.announcements],
        },
      };
    case ADD_ASSIGNMENT:
      return {
        ...state,
        course: {
          ...state.course,
          modules: [
            ...state.course.modules.map((module) => {
              // console.log(action.payload.modules[0] === module.id, 'checking');

              if (module.id === action.payload.modules[0]) {
                //module id from payload  ==> modules[0]
                return {
                  ...module,
                  assignments: [action.payload, ...module.assignments],
                };
              }
              return module;
            }),
          ],
        },
      };
    default:
      return state;
  }
};

export default courseReducer;
