import { sendRequest } from 'config/api';
import {
  ADD_SINGLE_ASSESSMENT,
  // REMOVE_SINGLE_ASSESSMENT,
  ASSESSMENT_QUESTONS_RESULT,
  GET_ERRORS_FOR_STARTED_QUESTIONS,
  GET_ASSESSSMENT_QUESTIONS,
  ADD_ASSESSSMENT_QUESTION
} from 'actions/types/assessmentTypes';
import { GET_ERRORS, GET_COURSE } from './types';
import { handleLoading } from 'actions/loadingAction';


export const getCourse = (courseId, userMode) => async (dispatch) => {
  try {
    dispatch(handleLoading());
    let url = userMode
      ? `/lms/course/${courseId}/?user_mode=${userMode}`
      : `/lms/course/${courseId}/`;

    const response = await sendRequest(url, null, 'GET');
    dispatch({
      type: GET_COURSE,
      payload: response.data,
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: GET_ERRORS,
      errorMessage: error.message,
    });
  }
};


export const createAssessment = (assessmentData, toast) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/assessment/`, assessmentData, 'POST');
    dispatch({
      type: ADD_SINGLE_ASSESSMENT,
      payload: data,
    });

    toast.success('Assessment created successfully');
    return;
  } catch ({ response, message }) {
    dispatch({
      type: GET_ERRORS,
      payload: response?.data,
    });
    return response.data || message;
  }
};



export const editAssessment = (courseId,assessmentid, assessmentData, toast) => async (
  dispatch,
) => {
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentid}/`,
      assessmentData,
      'PATCH',
    );
    dispatch({
      type: ADD_SINGLE_ASSESSMENT,
      payload: data,
    });
    dispatch(getCourse(courseId, 'INSTRUCTOR'));
    toast.success('Assessment updated successfully');
    return;
  } catch ({ response, message }) {
    dispatch({
      type: GET_ERRORS,
      payload: response?.data,
    });
    return response.data || message;
  }
};

export const deleteAssessment = (
  assessmentId,
courseId,
  toast,
) => async (dispatch) => {
  try {
    await sendRequest(`/assessment/${assessmentId}/`, '', 'DELETE');

   dispatch(getCourse(courseId, 'INSTRUCTOR'));
    toast.success('Assessment deleted successfully');
      // window.location.href = `/manage-courses/${courseId}/course-content/module/${moduleId}`;
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const getAllAssessment = (user, course) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/assessment/${user}/course/${course}/`,
      'GET',
    );

    dispatch({
      type: ASSESSMENT_QUESTONS_RESULT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS_FOR_STARTED_QUESTIONS,
      payload: error.message,
    });
  }
};

export const getAssessmentQuestions = (assessmentId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentId}/questions/`,
    );

    dispatch({
      type:   GET_ASSESSSMENT_QUESTIONS,
      payload: data,
    });
    return
  } catch ({response, message}) {
    dispatch({
      type: GET_ERRORS_FOR_STARTED_QUESTIONS,
      payload: message,
    });
    return response?.data || message
  }
};

export const addAssessmentQuestion = (assessmentId, question, toast) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentId}/question/`,
      question,
      'POST'
    );

    dispatch({
      type:   ADD_ASSESSSMENT_QUESTION,
      payload: data,
    });
    toast.success('Question added successfully')
    return
  } catch ({response, message}) {
    dispatch({
      type: GET_ERRORS_FOR_STARTED_QUESTIONS,
      payload: message,
    });
    return response?.data || message
  }
};



export const getAllAssessmentInstructor = (link) => async (dispatch) => {
  try {
    const { data } = await sendRequest(link, 'GET',);

    dispatch({
      type: ASSESSMENT_QUESTONS_RESULT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS_FOR_STARTED_QUESTIONS,
      payload: error.message,
    });
  }
};


export const getAllAssessmentInstructorReset = () => async (dispatch) => {
    dispatch({
      type: ASSESSMENT_QUESTONS_RESULT,
      payload: [],
    });
  
};