import {
  SEND_PROJECT,
  GET_PROJECT,
  GET_PROJECT_STATUS,
  SET_PROJECT_ERRORS,
  PROJECT_RESULT,
  GET_PROJECT_STUDENTS,
  // GRADE_STUDENT_PROJECTS
} from 'actions/types/projectTypes';

const initialState = {
  loading: false,
  loader: false,
  created: false,
  projects: {},
  users: {},
  project: {},
  result: '',
  status: '',
  allresult: [],
  errorMessage: null,
};

const projectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROJECT:
      return {
        ...state,
        project: payload,
      };
    case SEND_PROJECT:
      return {
        ...state,
        result: payload,
      };
    case GET_PROJECT_STATUS:
      return {
        ...state,
        status: payload,
      };
    case PROJECT_RESULT:
      return {
        ...state,
        allresult: payload,
      };

    case SET_PROJECT_ERRORS:
      return {
        ...state,
        status: payload,
      };
    case GET_PROJECT_STUDENTS:
      return {
        ...state,
        users: payload,
      };
    // case GRADE_STUDENT_PROJECTS:
    //   return {
    //     ...state,
    //     users: {

    //     }
    //   }
    default:
      return state;
  }
};

export default projectReducer;
