import { ADD_ANNOUNCEMENT, HANDLE_ANNOUNCEMENT_LOADING } from 'actions/types/announcementsTypes';

const initialState = {
  loading: false,
  annoucncements: {},
};

const assignmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        annoucncements: {
          ...state.annoucncements,
          ...payload,
        },
      };
    case HANDLE_ANNOUNCEMENT_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };

    default:
      return state;
  }
};

export default assignmentReducer;
