import { sendRequest } from 'config/api';
import { GET_ERRORS, HANDLE_LOADING } from './types';
import {
  GET_ALLUSERS,
  SEARCH_USER,
  ADD_USER,
  DELETE_USER,
  UPLOAD_USER,
  GET_SINGLE_USER,
  GET_All_USERS,
  RESET_PROGRESS,
} from './types/userTypes';

import { formatErrors, parseErrors } from '../helpers/formatErrors';

export const getUsers =
  (link, pageNumber = 1, pageSize = 12, role) =>
  async (dispatch) => {
    dispatch({
      type: HANDLE_LOADING,
    });
    try {
      const { data } = await sendRequest(
        link ||
          `/auth/users/?page=${pageNumber}&page_size=${pageSize}${
            role ? '&roles=' + role : ''
          }`,
      );
      dispatch({
        type: GET_ALLUSERS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error.message,
      });
    }
  };

export const getSingleUser = (userId) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(`/auth/users/${userId}/`);
    dispatch({
      type: GET_SINGLE_USER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const getSingleUserReset = () => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });

  dispatch({
    type: GET_SINGLE_USER,
    payload: [],
  });
};
// Add a new user
export const addUser = (formData, toast) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      '/auth/users/',
      formData,
      'POST',
      'multipart/form-data',
    );
    dispatch({
      type: ADD_USER,
      payload: data,
    });
    toast.success('User Created');
  } catch ({ response: { data } }) {
    dispatch({
      type: GET_ERRORS,
      payload: data,
    });
    toast.error(parseErrors(data));
    return formatErrors(data);
  }
};

//Upload a user
export const uploadUser =
  (formData, { onUpload }, toast) =>
  async (dispatch) => {
    dispatch({
      type: HANDLE_LOADING,
    });
    try {
      const { data } = await sendRequest(
        '/auth/users/upload_users/',
        formData,
        { onUpload },
        'POST',
        'multipart/form-data',
      );
      dispatch({
        type: UPLOAD_USER,
        payload: data,
      });
      toast.success('User Uploaded');
    } catch ({ response: { data } }) {
      dispatch({
        type: GET_ERRORS,
        payload: parseErrors(data),
      });
      toast.error(parseErrors(data));
    }
  };

//Edit User
export const editUser = (id, formData, toast, userMode) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      `/auth/users/${id}/?user_mode=${userMode}`,
      formData,
      'PATCH',
      'multipart/form-data',
    );
    dispatch({
      type: GET_SINGLE_USER,
      payload: { ...data, user_id: data.id },
    });
    toast.success('User Updated');
  } catch ({ response: { data } }) {
    toast.error(parseErrors(data));
    dispatch({
      type: GET_ERRORS,
      payload: data,
    });
    return formatErrors(data);
  }
};

//Delete User
export const deleteUser = (userId, toast, userMode) => async (dispatch) => {
  try {
    await sendRequest(
      `/auth/users/${userId}/?user_mode=${userMode}`,
      null,
      'DELETE',
    );
    dispatch({
      type: DELETE_USER,
      payload: userId,
    });
    toast.success('User Successfully Deleted');
  } catch ({ response, message }) {
    toast.error('An Error Ocurred.');
  }
};

//Reset User Progress
export const resetProgress = (userId, toast, courseId) => async (dispatch) => {
  try {
    await sendRequest(`/lms/course/${courseId}/${userId}/reset/`, null, 'POST');
    dispatch({
      type: RESET_PROGRESS,
      payload: { user: userId, id: courseId },
    });
    toast.success('User Progress Successfully Reset');
  } catch ({ response, message }) {
    toast.error('An Error Ocurred.');
  }
};




export const searchInstructors = (searchTerm) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/auth/users/?search=${searchTerm}&roles=INSTRUCTOR`,
      'GET',
    );

    if (data?.results?.length > 0) {
      const filteredData = data.results.map((instructor) => ({
        name: `${instructor.firstname} ${instructor.lastname}`,
        id: instructor.id,
      }));

      return filteredData;
    }

    return [];
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

//Search User
export const searchUser = (searchTerm, link) => async (dispatch) => {
  dispatch({
    type: HANDLE_LOADING,
  });
  try {
    const { data } = await sendRequest(
      link || `/auth/users/?search=${searchTerm}`,
    );
    dispatch({
      type: SEARCH_USER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

// Get Users
export const getAllUsers = (link) => async (dispatch) => {
  try {
    const { data } = await sendRequest(link);

    dispatch({
      type: GET_All_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
