export const FETCH_LEARNING_GROUP_BEGIN = 'FETCH_LEARNING_GROUP_BEGIN';
export const FETCH_LEARNING_GROUP_SUCCESS = 'FETCH_LEARNING_GROUP_SUCCESS';
export const FETCH_LEARNING_GROUP_FAILURE = 'FETCH_LEARNING_GROUP_FAILURE';
export const FETCH_SINGLE_LEARNING_GROUP_BEGIN =
  'FETCH_SINGLE_LEARNING_GROUP_BEGIN';
export const FETCH_SINGLE_LEARNING_GROUP_SUCCESS =
  'FETCH_SINGLE_LEARNING_GROUP_SUCCESS';
export const DELETE_LEARNING_GROUP_BEGIN = 'DELETE_LEARNING_GROUP_BEGIN';
export const DELETE_LEARNING_GROUP_SUCCESS = 'DELETE_LEARNING_GROUP_SUCCESS';
export const CREATE_LEARNING_GROUP_SUCCESS = 'CREATE_LEARNING_GROUP_SUCCESS';
export const CREATE_LEARNING_GROUP_FAILURE = 'CREATE_LEARNING_GROUP_FAILURE';
export const FETCH_LEARNING_GROUP_USERS_BEGIN =
  'FETCH_LEARNING_GROUP_USERS_BEGIN';
export const FETCH_LEARNING_GROUP_USERS_SUCCESS =
  'FETCH_LEARNING_GROUP_USERS_SUCCESS';
export const FETCH_LEARNING_GROUP_COURSES_BEGIN =
  'FETCH_LEARNING_GROUP_USERS_BEGIN';
export const FETCH_LEARNING_GROUP_COURSES_SUCCESS =
  'FETCH_LEARNING_GROUP_COURSES_SUCCESS';

export const ASSIGN_LEARNING_GROUP_SUCCESS = 'ASSIGN_LEARNING_GROUP_SUCCESS';
export const ASSIGN_LEARNING_GROUP_FAILURE = 'ASSIGN_LEARNING_GROUP_FAILURE';
export const UNASSIGN_LEARNING_GROUP_SUCCESS =
  'UNASSIGN_LEARNING_GROUP_SUCCESS';
export const UNASSIGN_LEARNING_GROUP_FAILURE =
  'UNASSIGN_LEARNING_GROUP_FAILURE';
export const EDIT_LEARNING_GROUP_SUCCESS = 'EDIT_LEARNING_GROUP_SUCCESS';
export const EDIT_LEARNING_GROUP_FAILURE = 'EDIT_LEARNING_GROUP_FAILURE';
export const SEARCH_LEARNING_GROUP_BEGIN = 'SEARCH_LEARNING_GROUP_BEGIN';
export const SEARCH_LEARNING_GROUP_SUCESS = 'SEARCH_LEARNING_GROUP_SUCESS';
export const SEARCH_LEARNING_GROUP_USERS_BEGIN =
  'SEARCH_LEARNING_GROUP_USERS_BEGIN';
export const SEARCH_LEARNING_GROUP_USERS_SUCCESS =
  'SEARCH_LEARNING_GROUP_USERS_BEGIN';

export const SEARCH_LEARNING_GROUP_COURSES_BEGIN =
  'SEARCH_LEARNING_GROUP_COURSE_BEGIN,';
export const SEARCH_LEARNING_GROUP_COURSES_SUCCESS =
  'SEARCH_LEARNING_GROUP_COURSES_SUCCESS';
