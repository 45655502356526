export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export const isArrayEmpty = (array) => {
  return array?.length < 1
}

/** 
  *@description returns an object that shows what has changed in two objects.
  *
  *if a test function is passed, the function is passed the key, value, and old object on each iteration.
  *
  *do with it (i.e key, value, oldObject) whatever you want your royal majesty.
  *
  *only make sure to return a boolean.
  *
  *(this is important because if nothing is returned, your function returns undefined by default which is falsy)
  **/
export const objectValuesChanged = (newObject, oldObject, test) => {
  return Object.fromEntries(
    Object.entries(newObject).filter(([key, value]) => {
      return test ? test(key, value, oldObject) : value !== oldObject[key];
    }),
  );
};

export const assert = (test, errorMessage, parameters = []) => {
  if (!test(...parameters)) {
    throw new Error(errorMessage);
  }
};

export const getBlobURL =
(window.URL && URL.createObjectURL.bind(URL)) ||
// eslint-disable-next-line no-undef
(window.webkitURL && webkitURL.createObjectURL.bind(webkitURL));


/**
 *@description https://stackoverflow.com/questions/36697749/html-get-color-in-rgb
 **/
export const hexToRgb = (hex) => {
  const hexArray = hex.match(/[A-Za-z0-9]{2}/g);
  const decimalHex = hexArray.map(function(v) { return parseInt(v, 16) });
  return decimalHex
}


//relative luminance
//https://en.wikipedia.org/wiki/Relative_luminance
export const isLightColor = (rgb) => {
  const normalisedRgb = rgb.map(each => each/255);
  const appliedLuminance =  (0.2126 * normalisedRgb[0] + 0.7152 * normalisedRgb[1] + 0.0722 * normalisedRgb[2]);
  return appliedLuminance > 0.5
}

export const make2dArray = (array, size) => {
  const newArr = [];
  while (array.length) newArr.push(array.splice(0, size));
  return newArr;
};

export const getCorrectTime = (intendingDate) => {
  var date = new Date(intendingDate)
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return(new Date(date.getTime() + userTimezoneOffset));
}

export const paymentProvidersList = () => {
  return {
    PAYSTACK: {name:'Paystack', provider:'paystack', logo:'https://avatars.githubusercontent.com/u/14998667?s=200&v=4'},
    REMITA: {name:'Remitta', provider:'remitta', logo:'https://desk.zoho.com/portal/api/departments/174878000004489092/logo?orgId=642422222'},
  }
}