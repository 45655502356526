// customToast.js
import { toast } from 'react-toastify';
import { css } from 'glamor';

const CustomToast = {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: {
        color: '#fff',
        minHeight: '60px',
        background: '#161531',
        borderRadius: '8px',
        marginTop: '91px',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '19px',
        padding: '13.5px 25px 13.5px 55px',
        boxShadow: '2px 2px 20px 2px rgba(0,0,0,0.3)',
      },
      hideProgressBar: true,
      progressClassName: css({
        background: '#000',
      }),
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: {
        color: '#fff',
        minHeight: '60px',
        background: '#161531',
        borderRadius: '8px',
        marginTop: '91px',
        boxShadow: '2px 2px 20px 2px rgba(0,0,0,0.3)',
      },
      progressClassName: css({
        background: '#EE0022',
      }),
    });
  },
  info(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: 'toast-info-container toast-info-container-after',
      progressClassName: css({
        background: '#07F',
      }),
    });
  },
  warning(msg, options = {}) {
    return toast.warning(msg, {
      ...options,
      className: 'toast-info-container toast-info-container-after',
      progressClassName: css({
        background: '#07F',
      }),
    });
  },
};

export default CustomToast;
