import { sendRequest } from 'config/api';
import axios from 'axios';
import { STARTSAVINGSCORMPROGRESS } from './types/trackScormTypes';
import { getStudentCourse } from 'actions';
import { getCourse } from './courseActions';
import { getLesson } from './lessonActions';
import convert from 'xml-js';

export const startSavingScormProgress = (lessonProgressInfo) => async (
  dispatch,
) => {
  try {
    const { data } = await sendRequest(
      '/lms/course/lesson/progress/',
      lessonProgressInfo,
      'POST',
    );
    dispatch({
      type: STARTSAVINGSCORMPROGRESS,
      payload: data,
    });
    dispatch(getCourse(lessonProgressInfo.course, 'STUDENT'));
    dispatch(getLesson(lessonProgressInfo.lesson));
  } catch ({ response }) {
    console.log(response?.data, 'this');
    return response?.data?.message
  }
};

export const updateScormProgress = (
  lessonProgressInfo,
  progressId,
) => async () => {
  try {
    await sendRequest(
      `/lms/course/lesson/progress/${progressId}/`, //variable represents the progressId
      lessonProgressInfo,
      'PATCH',
    );
  } catch ({ message, response }) {
    console.log(message, 'frm backend error', response?.data);
    if (
      response?.data?.non_field_errors &&
      response.data.non_field_errors.length > 0 &&
      response.data.non_field_errors[0].includes('schedule')
    ) {
        window.location.href = '/courses/active';
    }
    return response?.data?.message
  }
};

export const makeScormAsComplete = (
  lessonToCompleteInfo,
  refresh = true,
) => async (dispatch) => {
  try {
    await sendRequest(
      '/lms/course/progress/add/',
      lessonToCompleteInfo,
      'post',
    );
    if (refresh) dispatch(getStudentCourse(lessonToCompleteInfo.course)); //course in this case represents the courseId

    return;
  } catch ({ message, response }) {
    // toast.error(JSON.stringify(message || response?.data));
    return message || response?.data?.message;
  }
};

export const getXmlVersion = (url) => async (dispatch) => {
  try {
    //make an api call to fetch the xml scorm content
    const response = await axios.get(url);

    /**
     * our goal is to get the entry path for the scorm file.
     *
     * Different scorm files have different entry files (similar to index.js)
     *
     * but the path to the entry file is always specified in the resources tag on the xml
     *
     * we convert xml to json, then to a javascript object so we can do "something.anotherthing"
     */
    const xmlToJSON = convert.xml2json(response?.data, {
      compact: true,
      spaces: 4,
    });
    //once converted to JSON, we need that to convert to a regular javascript object...
    const jsonToObject = JSON.parse(xmlToJSON);

    const { href } = jsonToObject.manifest.resources.resource._attributes;

    return href;
  } catch ({ response }) {
    // toast.error(JSON.stringify(error.message || error.response));
    return response?.data?.message;
  }
};
