import React from 'react';
import { ToastContainer } from 'react-toastify';
import CustomToast from '../components/Toast/CustomToast';
import CheckMark from '../components/Toast/CheckMark';
import CloseToast from '../components/Toast/CloseToast';
import 'react-toastify/dist/ReactToastify.min.css';

const ToastContext = React.createContext();

function ToastProvider(props) {
  return (
    <ToastContext.Provider value={CustomToast} {...props}>
      <ToastContainer
        autoClose={5000}
        CheckMark={CheckMark}
        CloseToast={CloseToast}
        position="top-center"
      />
      {props.children}
    </ToastContext.Provider>
  );
}

const useToast = () => React.useContext(ToastContext);

export { ToastProvider, useToast };
