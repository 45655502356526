import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import Footer from './components/Footer/Footer';
import PrivateRoute from './components/PrivateRoute';
import { ToastProvider } from './context/toastContext';
import { requestPermissionToUseFirebase } from 'config/firebase.js';
import store from './store';
import setAuthToken from './helpers/setAuthToken';
import { privateRoutes, publicRoutes } from './Routes';
import { setCurrentUser, logout, getCompany } from 'actions/authActions';
import { getSubdomain } from 'helpers/getSubdomain';
import './App.scss';
import { NavigationBar } from 'components/Navbar';
import { initialiseObserver } from 'helpers/initialiseObserver';
import { setActiveRole } from 'actions/roleAction';
import { setUpdatedUser } from 'actions/authActions';
import withClearCache from './clearCache';
import { ScaleLoader } from 'react-spinners';
import { handleUnssuportedBrowsers } from 'helpers/detectBrowser';

initialiseObserver();

// Check for token
if (localStorage.access) {
  // Set the auth token header auth
  setAuthToken(localStorage.access);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.access);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  store.dispatch(setUpdatedUser(decoded));
  store.dispatch(getCompany(getSubdomain()));
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logout());
    // [TODO] Clear current
    // store.dispatch(clearCurrentProfile());
    // Redirect to login
    window.location.href = '/signin';
  } else {
    if (localStorage.activeRole) {
      store.dispatch(setActiveRole(localStorage.activeRole));
    }
  }
}
function MainApp() {
  requestPermissionToUseFirebase(store);

  handleUnssuportedBrowsers();

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <ToastProvider>
            <NavigationBar />
            <main>
              <Suspense
                fallback={
                  <div id="overlay" className="center-loader">
                    {' '}
                    <ScaleLoader />
                  </div>
                }
              >
                <Switch>
                  {publicRoutes.map((route, index) => {
                    return (
                      <Route
                        path={route.url}
                        component={route.component}
                        key={index}
                        exact
                      />
                    );
                  })}
                </Switch>
                <Switch>
                  {privateRoutes.map((route, index) => {
                    return (
                      <PrivateRoute
                        exact={route.exact ? route.exact : false}
                        path={route.url}
                        component={route.component}
                        key={index}
                      />
                    );
                  })}
                </Switch>
              </Suspense>
            </main>
            <Footer />
          </ToastProvider>
        </div>
      </Router>
    </Provider>
  );
}

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}
export default App;
