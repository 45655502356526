import { sendRequest } from 'config/api';
import {
  FETCH_LEARNING_GROUP_SUCCESS,
  FETCH_LEARNING_GROUP_BEGIN,
  FETCH_SINGLE_LEARNING_GROUP_BEGIN,
  FETCH_SINGLE_LEARNING_GROUP_SUCCESS,
  CREATE_LEARNING_GROUP_SUCCESS,
  DELETE_LEARNING_GROUP_SUCCESS,
  DELETE_LEARNING_GROUP_BEGIN,
  FETCH_LEARNING_GROUP_USERS_BEGIN,
  FETCH_LEARNING_GROUP_USERS_SUCCESS,
  FETCH_LEARNING_GROUP_COURSES_BEGIN,
  FETCH_LEARNING_GROUP_COURSES_SUCCESS,
  ASSIGN_LEARNING_GROUP_SUCCESS,
  UNASSIGN_LEARNING_GROUP_SUCCESS,
  EDIT_LEARNING_GROUP_SUCCESS,
  SEARCH_LEARNING_GROUP_BEGIN,
  SEARCH_LEARNING_GROUP_SUCESS,
  SEARCH_LEARNING_GROUP_USERS_BEGIN,
  SEARCH_LEARNING_GROUP_USERS_SUCCESS,
  SEARCH_LEARNING_GROUP_COURSES_BEGIN,
  SEARCH_LEARNING_GROUP_COURSES_SUCCESS,
} from './types/learningGroupTypes';
import { GET_ERRORS, CLEAR_ERRORS } from './types';

// Actions for fetching learning groups
export const fetchLearningGroupBegin = () => ({
  type: FETCH_LEARNING_GROUP_BEGIN,
  payload: {
    isLoading: true,
  },
});

export const fetchLearningGroupSuccess = (data) => ({
  type: FETCH_LEARNING_GROUP_SUCCESS,
  payload: { isLoading: false, learningGroups: data },
});

export const fetchLearningGroup = (link) => async (dispatch) => {
  try {
    dispatch(fetchLearningGroupBegin());
    const { data } = await sendRequest(link || '/auth/group/');
    dispatch(fetchLearningGroupSuccess(data));
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { errorMessages: 'An error occured' },
    });
  }
};

//Actions for fetching a single learning group
export const fetchSingleLearningGroupBegin = () => ({
  type: FETCH_SINGLE_LEARNING_GROUP_BEGIN,
  payload: {
    isLoading: true,
  },
});

export const fetchSingleLearningGroupSuccess = (data) => ({
  type: FETCH_SINGLE_LEARNING_GROUP_SUCCESS,
  payload: { isLoading: false, singleLearningGroup: data },
});

export const fetchSingleLearningGroup = (learningGroupId) => async (
  dispatch,
) => {
  try {
    dispatch(fetchSingleLearningGroupBegin());
    const { data } = await sendRequest(`/auth/group/${learningGroupId}`);
    dispatch(fetchSingleLearningGroupSuccess(data));
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { isLoading: false, errorMessage: error?.response?.data },
    });
  }
};

// Actions for creating Learning groups
export const createLearningGroupsSuccess = () => ({
  type: CREATE_LEARNING_GROUP_SUCCESS,
  payload: { isLoading: false },
});

export const createLearningGroupsFailure = (message) => ({
  type: GET_ERRORS,
  payload: message,
});

export const clearcreateLearningGroupsErrors = () => ({ type: CLEAR_ERRORS });

export const createLearningGroup = (learningGroupData, toast) => async (
  dispatch,
) => {
  try {
    dispatch(clearcreateLearningGroupsErrors());
    await sendRequest('/auth/group/', learningGroupData, 'POST');
    dispatch(createLearningGroupsSuccess());
    toast.success('Learning Group created successfully');
    dispatch(fetchLearningGroup());
  } catch (error) {
    toast.error('Could not create learning group');
    return dispatch({
      type: GET_ERRORS,
      payload: error?.response?.data?.exception,
    });
  }
};

//Actions for deleting learning groups
export const deleteLearningGroupBegin = () => ({
  type: DELETE_LEARNING_GROUP_BEGIN,
  payload: {
    isDeleting: true,
  },
});

export const deleteLearningGroupEnd = () => ({
  type: DELETE_LEARNING_GROUP_SUCCESS,
  payload: {
    isDeleting: false,
  },
});

export const deleteLearningGroup = (learningGroupId, toast) => async (
  dispatch,
) => {
  try {
    dispatch(deleteLearningGroupBegin());
    await sendRequest(`/auth/group/${learningGroupId}`, null, 'DELETE');
    toast.success('Learning group deleted');
    dispatch(deleteLearningGroupEnd());

    dispatch(fetchLearningGroup());
  } catch (error) {
    toast.error('Could not delete learning group');
    dispatch(deleteLearningGroupEnd());
    dispatch({
      type: GET_ERRORS,
      payload: error?.response?.data?.exception,
    });
  }
};

// Actions for fetching users in learning group
export const fetchLearningGroupUsersBegin = () => ({
  type: FETCH_LEARNING_GROUP_USERS_BEGIN,
  payload: {
    isFetching: true,
  },
});

export const fetchLearningGroupUsersSuccess = (data) => ({
  type: FETCH_LEARNING_GROUP_USERS_SUCCESS,
  payload: { isFetching: false, learningGroupUsers: data },
});

export const fetchLearningGroupUsers = (learningGroupId, link) => async (
  dispatch,
) => {
  let url = link ? link : `/auth/group/${learningGroupId}/users`;
  try {
    dispatch(fetchLearningGroupUsersBegin());
    const { data } = await sendRequest(url);
    dispatch(fetchLearningGroupUsersSuccess(data));
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { isLoading: false, errorMessage: error?.response?.data },
    });
  }
};

// Actions for fetching courses in learning group

export const fetchLearningGroupCoursesBegin = () => ({
  type: FETCH_LEARNING_GROUP_COURSES_BEGIN,
  payload: {
    isFetching: true,
  },
});

export const fetchLearningGroupCoursesSuccess = (data) => ({
  type: FETCH_LEARNING_GROUP_COURSES_SUCCESS,
  payload: { isFetching: false, learningGroupCourses: data },
});

export const fetchLearningGroupCourses = (learningGroupId) => async (
  dispatch,
) => {
  try {
    dispatch(fetchLearningGroupCoursesBegin());
    const { data } = await sendRequest(
      `/auth/group/${learningGroupId}/courses`,
    );
    dispatch(fetchLearningGroupCoursesSuccess(data));
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { isFetching: false, errorMessage: error?.response?.data },
    });
  }
};

// Actions to assign to learning group

export const assignLearningGroupSuccess = () => ({
  type: ASSIGN_LEARNING_GROUP_SUCCESS,
  payload: { isLoading: false, message: 'Assigned Successfully' },
});

export const assignLearningGroupFailure = (message) => ({
  type: GET_ERRORS,
  payload: message,
});

export const assignLearningGroup = (
  assignLearningGroupData,
  learningGroupId,
  toast,
  allSelected,
  searchParams,
) => async (dispatch) => {
  let data = {
    all_users: assignLearningGroupData.all_users,
    search: searchParams,
  };

  try {
    dispatch({ type: CLEAR_ERRORS });
    data = data.all_users ? data : assignLearningGroupData;
    await sendRequest(`/auth/group/${learningGroupId}/assign/`, data, 'POST');

    if (assignLearningGroupData.users) {
      toast.success('Users assigned Successfully');
      await dispatch(fetchLearningGroupUsers(learningGroupId));
    } else {
      toast.success('Courses assigned Successfully');
      await dispatch(fetchLearningGroupCourses(learningGroupId));
    }
  } catch (error) {
    dispatch(assignLearningGroupFailure(error));
  }
};

// Action to Unassign from learning group
export const unAssignLearningGroupSuccess = () => ({
  type: UNASSIGN_LEARNING_GROUP_SUCCESS,
  payload: { isLoading: false, message: 'Unassigned Successfully' },
});

export const unAssignLearningGroupFailure = (message) => ({
  type: GET_ERRORS,
  payload: message,
});

export const unAssignLearningGroup = (
  unAssignLearningGroupData,
  learningGroupId,
  toast,
) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERRORS });
    await sendRequest(
      `/auth/group/${learningGroupId}/unassign/`,
      unAssignLearningGroupData,
      'POST',
    );
    if (unAssignLearningGroupData.users) {
      toast.success('User removed successfully');
      await dispatch(fetchLearningGroupUsers(learningGroupId));
    } else {
      toast.success('Courses removed successfully');
      await dispatch(fetchLearningGroupCourses(learningGroupId));
    }
  } catch (error) {
    toast.warn('An error occured');
    dispatch(unAssignLearningGroupFailure(error));
  }
};

// Actions for deleting learning groups

export const editLearningGroupSuccess = () => ({
  type: EDIT_LEARNING_GROUP_SUCCESS,
  payload: { isLoading: false },
});

export const editLearningGroupFailure = (message) => ({
  type: GET_ERRORS,
  payload: message,
});

export const editLearningGroup = (
  learningGroupData,
  learningGroupId,
  toast,
) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERRORS });
    await sendRequest(
      `/auth/group/${learningGroupId}/`,
      learningGroupData,
      'PATCH',
    );
    toast.success('Learning Group edited successfully');
    dispatch(editLearningGroupSuccess());
    dispatch(fetchSingleLearningGroup(learningGroupId));
    dispatch(fetchLearningGroup());
  } catch (error) {
    toast.error('An error occured');
    switch (error.response.status) {
      case 400:
        return dispatch(editLearningGroupFailure(error?.response?.data));
      case 500:
        return dispatch(editLearningGroupFailure(['An error occured']));
      default:
        return null;
    }
  }
};

export const searchLearningGroupBegin = () => ({
  type: SEARCH_LEARNING_GROUP_BEGIN,
  payload: {
    isLoading: true,
  },
});

export const searchLearningGroupSuccess = (data) => ({
  type: SEARCH_LEARNING_GROUP_SUCESS,
  payload: { isLoading: false, learningGroups: data },
});

export const searchLearningGroup = (searchTerm, link) => async (dispatch) => {
  try {
    dispatch(searchLearningGroupBegin());
    if (!searchTerm) {
      dispatch(fetchLearningGroup(link));
      return;
    }
    const { data } = await sendRequest(
      link || `/auth/group/?search=${searchTerm}`,
    );
    dispatch(searchLearningGroupSuccess(data));
    return data;
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { isFetching: false, errorMessage: error?.response?.data },
    });
  }
};

export const searchLearningGroupUsersBegin = () => ({
  type: SEARCH_LEARNING_GROUP_USERS_BEGIN,
  payload: {
    isFetching: true,
  },
});

export const searchLearningGroupUsersSuccess = (data) => ({
  type: SEARCH_LEARNING_GROUP_USERS_SUCCESS,
  payload: { isFetching: false, learningGroupUsers: data },
});

export const searchLearningGroupUsers = (searchTerm, learningGroupId) => async (
  dispatch,
) => {
  try {
    dispatch(searchLearningGroupUsersBegin());

    if (!searchTerm) {
      dispatch(fetchLearningGroupUsers(learningGroupId));
      return;
    }
    const { data } = await sendRequest(
      `/auth/group/${learningGroupId}/users/?search=${searchTerm}`,
    );
    dispatch(searchLearningGroupUsersSuccess(data));
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { isFetching: false, errorMessage: error?.response?.data },
    });
  }
};

export const searchLearningGroupCoursesBegin = () => ({
  type: SEARCH_LEARNING_GROUP_COURSES_BEGIN,
  payload: {
    isFetching: true,
  },
});

export const searchLearningGroupCoursesSuccess = (data) => ({
  type: SEARCH_LEARNING_GROUP_COURSES_SUCCESS,
  payload: { isFetching: false, learningGroupCourses: data },
});

export const searchLearningGroupCourses = (
  searchTerm,
  learningGroupId,
) => async (dispatch) => {
  try {
    dispatch(searchLearningGroupCoursesBegin());

    if (!searchTerm) {
      dispatch(fetchLearningGroupCourses(learningGroupId));
      return;
    }
    const { data } = await sendRequest(
      `/auth/group/${learningGroupId}/courses/?search=${searchTerm}`,
    );
    dispatch(searchLearningGroupCoursesSuccess(data));
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { isFetching: false, errorMessage: error?.response?.data },
    });
  }
};
