import { sendRequest } from 'config/api';
import {
  FETCH_QUESTION,
  IS_LOADING,
  ASSESSMENT_SENT_QUESTONS_SUCCESS,
  ASSESSMENT_QUESTONS_STATUS,
  ASSESSMENT_QUESTONS_RESULT,
  ASSESSMENT_COMPLETED
} from 'actions/types/questionTypes';
import { GET_ERRORS, GET_ERRORS_FOR_STARTED_QUESTIONS } from './types';

// import { getAssessmentQuestions } from 'actions/index';

export const handleLoading = () => ({
  type: IS_LOADING,
});
export const getQuestions = (assessmentId) => async (dispatch) => {
  try {
    dispatch(handleLoading());
    const { data } = await sendRequest(
      `/assessment/${assessmentId}/questions/`,
    );
    dispatch({
      type: FETCH_QUESTION,
      payload: data,
    });
    dispatch(handleLoading());
  } catch (error) {
    dispatch(handleLoading());
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const createQuestions = (assessmentId, data, toast) => async (
  dispatch,
) => {
  try {
    await sendRequest(`/assessment/${assessmentId}/question/`, data, 'POST');
    dispatch(getQuestions(assessmentId));
    toast.success('Question created successfully');
    return;
  } catch ({ response, message }) {
    // toast.error('An error occured');
    return response?.data || message;
  }
};

export const editQuestions = (assessmentId, questionId, data, toast) => async (
  dispatch,
) => {
  try {
    await sendRequest(
      `/assessment/${assessmentId}/question/${questionId}/`,
      data,
      'PATCH',
    );
    toast.success('Question updated successfully');
    await dispatch(getQuestions(assessmentId));

    return;
  } catch ({ response, message }) {
    // toast.error('An error occured');
    return response?.data.message || message;
  }
};

export const deleteQuestion = (assessmentId, questionid, toast) => async (
  dispatch,
) => {
  try {
    await sendRequest(`/assessment/question/${questionid}/`, '', 'DELETE');
    dispatch(getQuestions(assessmentId));
    toast.success('Question deleted successfully');
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const deleteAssessment = (assessmentData, toast) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentData}`,
      '',
      'DELETE',
    );
    dispatch({
      type: FETCH_QUESTION,
      payload: data,
    });
    toast.success('Assessment deleted successfully');
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
  }
};

export const assessmentStatus = (assessmentId, moduleID) => async (
  dispatch,
) => {
  dispatch(handleLoading());
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentId}/status/${moduleID}/`,
      'GET',
    );
    dispatch({
      type: ASSESSMENT_QUESTONS_STATUS,
      payload: data,
    });
   
    dispatch(getAssessmentResults(assessmentId, moduleID));
  } catch (error) {
    dispatch({
      type: GET_ERRORS_FOR_STARTED_QUESTIONS,
      payload: error.message,
    });
  }
};

export const startAssessment = (
  assessmentId,
  toast,
  moduleName,
  objectmoduleID,
  moduleID,
) => async (dispatch) => {
  dispatch(handleLoading());
  try {
    await sendRequest(
      `/assessment/${assessmentId}/start/`,
      objectmoduleID,
      'POST',
    );
    toast.success(`You have started ${moduleName} assessment`);
    dispatch(handleLoading());
    dispatch(assessmentStatus(assessmentId, moduleID));
  } catch (error) {
    toast.error('An error occured');
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

export const sendQuestions = (assessmentId, Formdata, toast, module) => async (
  dispatch,
) => {
  dispatch(handleLoading());
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentId}/attempt/${module}/`,
      Formdata,
      'POST',
    );
    dispatch({
      type: ASSESSMENT_SENT_QUESTONS_SUCCESS,
      payload: data,
    });
    // toast.success('Question attempted successfully');
    dispatch(handleLoading());
  } catch (error) {
    toast.error('An error occured');
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
    dispatch(handleLoading());
  }
};

export const completeAssessment = (
  assessmentId,
  toast,
  moduleName,
  moduleID,
) => async (dispatch) => {
  dispatch(handleLoading());
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentId}/complete/${moduleID}/`,
    );
    dispatch({
      type: IS_LOADING,
      payload: data,
    });

    dispatch(handleLoading());
    toast.success(`You have completed ${moduleName} assessment`);
  } catch (error) {
    toast.error('An error occured');
    dispatch({
      type: GET_ERRORS,
      payload: error?.response?.data,
    });
  }
};

export const getAssessmentResults = (assessmentId, moduleID) => async (
  dispatch,
) => {
  dispatch(handleLoading());
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentId}/result/${moduleID}/`,
      'GET',
    );
    // toast.success(`results for ${moduleName} assessment retrieved successfully`);
    dispatch({
      type: ASSESSMENT_QUESTONS_RESULT,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS_FOR_STARTED_QUESTIONS,
      payload: error.message,
    });
  }
};



export const resetAssessmentResults = (assessmentId, moduleID, toast) => async (
  dispatch,
) => {
  dispatch(handleLoading());
 
  try {
    const { data } = await sendRequest(
      `/assessment/${assessmentId}/reset/${moduleID}/`,
      'GET',
    );
   toast.success(`You have restarted  assessment`);
    dispatch({
      type: ASSESSMENT_QUESTONS_RESULT,
      payload: data,
    });

    dispatch(assessmentStatus(assessmentId, moduleID));

  } catch (error) {
    dispatch({
      type: GET_ERRORS_FOR_STARTED_QUESTIONS,
      payload: error.message,
    });
  }
};

export const setAssessmentAsCompleted = () => dispatch => {
  dispatch({
    type: ASSESSMENT_COMPLETED,
  })
  
}