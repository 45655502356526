import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchNotifications,
  clearNotifications,
  markNotificationAsRead,
} from 'actions';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import moment from 'moment';


export default function NotificationContent({ closeNoticationDropDown }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notification);
  const { results } = notifications;
  const notificationWrapperRef = useRef(null);


  const getNotifications = async () => {
    setLoading(true);
    await dispatch(fetchNotifications());
    setLoading(false);
  };

  useEffect(() => {
    getNotifications();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleClickOutside = (event) => {
    if (
      notificationWrapperRef &&
      !notificationWrapperRef.current.contains(event.target)
    ) {
      closeNoticationDropDown();
    }
  };

  const markAsRead = (result) => {
    dispatch(
      markNotificationAsRead(result.id, {
        is_read: true,
      }),
    );
    window.location.href = result?.data?.data?.link
  };

  return (
    <div className="notification-sd__wrapper" ref={notificationWrapperRef}>
      {loading ? (
        <>
          {Array.apply(0, Array(10)).map((_, index) => (
            <Skeleton heigth={300} width={`100%`} key={index} />
          ))}
        </>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <h1>
              Notifications{' '}
              {results?.length > 0 && (
                <span className="notification-sd__badge">
                  {results?.length}
                </span>
              )}
            </h1>
            <span
              role="button"
              onClick={async () => {
                setLoading(true);
                await dispatch(clearNotifications());
                setLoading(false);
              }}
            >
              Clear All
            </span>
          </div>
          <hr />
          {results?.length > 0 ? (
            results?.map((result) => (
              <NotificationItem
                result={result}
                key={result.id}
                handleMarkAsRead={() => markAsRead(result)}
              />
            ))
          ) : (
            <div>You have no new notifications</div>
          )}
        </div>
      )}
    </div>
  );
}

const NotificationItem = ({ result, handleMarkAsRead }) => (
  <div
    className={
      !result.is_read
        ? 'notification-sd__new-notification'
        : 'notification-sd__old-notification'
    }
  >
    <i className="fa fa-circle" aria-hidden="true"></i>
    <div className="notification-sd__text" onClick={handleMarkAsRead}>
      <p className="mb-0">{result?.data?.notification?.body}</p>
      <span>{moment(result.created_at).fromNow()}</span>
    </div>
  </div>
);
