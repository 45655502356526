const DOMAIN = process.env.REACT_APP_DOMAIN || 'v3.peerlesslms.com';

export const getSubdomain = () => {
  let hostname = window.location.hostname.toLowerCase();
  const hasDomain = hostname.includes(DOMAIN) || hostname.includes('localhost');
  if (hasDomain) {
    const hostnameSections = hostname.split('.');
    return hostnameSections[0];
  }
  return hostname;
};

export const redirectToSignUp = (subDomain) => {
  return subDomain === 'accounts';
};

console.log(DOMAIN);
