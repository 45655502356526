import {
  SEND_ASSIGNMENT,
  GET_ASSIGNMENT,
  SET_ASSIGNMENT_ERRORS,
  GET_ASSIGNMENT_STATUS,
  ASSIGNMENT_QUESTONS_RESULT,
  ADD_ASSIGNMENT
} from 'actions/types/assignmentTypes';

const initialState = {
  loading: false,
  loader: false,
  created: false,
  assignments: {},
  assignment: {},
  result: '',
  status: '',
  allresult: [],
  errorMessage: null,
};

const assignmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ASSIGNMENT:
      return {
        ...state,
        assignment: payload,
        loading: false,
      };
    case SEND_ASSIGNMENT:
      return {
        ...state,
        loader: false,
        result: payload,
        loading: false,
      };
    case SET_ASSIGNMENT_ERRORS:
      return {
        ...state,
        loader: false,
        errorMessage: payload,
      };
    case ASSIGNMENT_QUESTONS_RESULT:
      return {
        ...state,
        allresult: payload,
        loading: false,
      };

    case GET_ASSIGNMENT_STATUS:
      return {
        ...state,
        status: payload,
        loading: false,
      };
    case ADD_ASSIGNMENT:
      return {
        ...state,// need to update state
      };
    default:
      return state;
  }
};

export default assignmentReducer;
