export const GET_MESSAGES_BEGIN = 'GET_MESSAGES_BEGIN';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE';
export const GET_CONVERSATION_BEGIN = 'GET_CONVERSATION_BEGIN';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const GET_CONVERSATION_FAILURE = 'GET_CONVERSATION_FAILURE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const DELETE_MESSAGE_ERROR = 'DELETE_MESSAGE_ERROR';
export const UPDATE_MESSAGE_LIST = 'UPDATE_MESSAGE_LIST';
export const UPDATE_MESSAGE_LIST_ERROR = 'UPDATE_MESSAGE_LIST_ERROR';
