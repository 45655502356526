import {
  ADD_EVENT,
  GET_EVENTS,
  GROUP,
  NEXT,
  CLOSE_MODAL,
  ADD_SELECTED,
  REMOVE_SELECTED,
  USER,
  REMOVE_ALL_SELECTED,
  ADD_LINK,
  BEGIN_EDIT,
  EDIT_EVENT,
} from 'actions/types/eventTypes';

const initialState = {
  nextForm: null,
  previousForm: null,
  selected: { users: [], groups: [] },
  eventInEdit: null,
  eventId: null,
  events: {
    results: [],
  },
  allSelected: false,
  url: null,
};

const eventReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_EVENT:
      return {
        ...state,
        eventId: payload.eventId,
      };
    case BEGIN_EDIT:
      return {
        ...state,
        eventInEdit: payload.details,
      };
    case EDIT_EVENT:
      return {
        ...state,
        eventId: payload?.eventDetails.id,
        selected: {
          users: payload?.eventDetails?.attendees
            ?.filter((each) => each.attendee_type === 'USER')
            .map((eachAttendee) => ({
              ...payload.attendees.userDetails.find(
                (each) => eachAttendee.user === each.id,
              ),
              attendeeDetail: eachAttendee,
            }))
            .filter((each) => Boolean(each.id)),
          groups: payload?.eventDetails?.attendees
            ?.filter((each) => each.attendee_type === 'GROUP')
            .map((eachAttendee) => ({
              ...payload.attendees.groupDetails.find(
                (each) => eachAttendee.group === each.id,
              ),
              attendeeDetail: eachAttendee,
            }))
            .filter((each) => Boolean(each.id)),
        },
      };
    case GET_EVENTS:
      return {
        ...state,
        events: {
          ...payload.data,
        },
      };
    case NEXT:
      return {
        ...state,
        nextForm: payload.next,
        previousForm: payload.prev,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        nextForm: null,
        previousForm: null,
        eventInEdit: null,
      };
    case ADD_SELECTED:
      if (Array.isArray(payload.entity)) {
        if (payload.type === USER) {
          return {
            ...state,
            selected: { ...state.selected, users: payload.entity },
            allSelected: true,
          };
        }
        if (payload.type === GROUP) {
          return {
            ...state,
            selected: { ...state.selected, groups: payload.entity },
            allSelected: true,
          };
        }
      }
      if (payload.type === USER) {
        return {
          ...state,
          selected: {
            ...state.selected,
            users: [...state.selected.users, payload.entity],
          },
        };
      }
      if (payload.type === GROUP) {
        return {
          ...state,
          selected: {
            ...state.selected,
            groups: [...state.selected.groups, payload.entity],
          },
        };
      }
      break;
    case REMOVE_SELECTED:
      let filteredSelected;
      
      if (payload.type === USER) {
        filteredSelected = state.selected.users.filter((eachEntity) => {
          return eachEntity?.id !== payload?.entityId;
        });
        return {
          ...state,
          selected: { ...state.selected, users: filteredSelected },
        };
      }

      if (payload.type === GROUP) {
        filteredSelected = state.selected.groups.filter(
          (eachEntity) => eachEntity.id !== payload.entityId,
        );
        return {
          ...state,
          selected: { ...state.selected, groups: filteredSelected },
        };
      }

      break;
    case REMOVE_ALL_SELECTED:
      if (payload.type === USER) {
        return {
          ...state,
          selected: {
            ...state.selected,
            users: [],
          },
          allSelected: false,
        };
      }

      if (payload.type === GROUP) {
        return {
          ...state,
          selected: {
            ...state.selected,
            groups: [],
          },
          allSelected: false,
        };
      }
      break;
    case ADD_LINK:
      return {
        ...state,
        url: payload.url,
      };
    default:
      return state;
  }
};

export default eventReducer;
