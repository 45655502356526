import { sendRequest } from 'config/api';
import {
  // SEND_PROJECT,
  GET_PROJECT,
  GET_PROJECT_STATUS,
  // SET_PROJECT_ERRORS,
  SET_PROJECT_ERRORS,
  PROJECT_RESULT,
  ADD_PROJECT,
  GET_PROJECT_STUDENTS,
  GRADE_STUDENT_PROJECTS
} from 'actions/types/projectTypes';
// import { GET_ERRORS } from './types';
// import { handleLoading } from 'actions/loadingAction';

export const getProject = (projectId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/task/project/${projectId}/`);
    dispatch({
      type: GET_PROJECT,
      payload: data,
    });
    //   dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: SET_PROJECT_ERRORS,
      payload: error.response,
    });
  }
};


export const getStudentProject = (course) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/task/project/course/${course}/`);
    dispatch({
      type: PROJECT_RESULT,
      payload: data,
    });
    //   dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: SET_PROJECT_ERRORS,
      payload: error.response,
    });
  }
};






export const getProjectStatus = (projectId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/task/project_result/${projectId}/student/`,
    );

    dispatch({
      type: GET_PROJECT_STATUS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SET_PROJECT_ERRORS,
      payload: error.response,
    });
  }
};


export const getProjectStateStudent = (projectId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/task/project_result/${projectId}/`);

    dispatch({
      type: GET_PROJECT_STATUS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SET_PROJECT_ERRORS,
      payload: error.response,
    });
  }
};

export const getAllProject = (course) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/task/project/instructor/course/${course}/`,
      'GET',
    );

    dispatch({
      type: PROJECT_RESULT,
      payload: data,
    });

    // dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: SET_PROJECT_ERRORS,
      payload: error.message,
    });
  }
};


export const getAllProjectInstructor = (link) => async (dispatch) => {
  try {
    const { data } = await sendRequest(link, 'GET',);

    dispatch({
      type: PROJECT_RESULT,
      payload: data,
    });

    // dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: SET_PROJECT_ERRORS,
      payload: error.message,
    });
  }
};

export const addProject = (project, courseId, toast) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/task/project/`, project, 'POST');
    dispatch({
      type: ADD_PROJECT,
      payload: data,
    });
       toast.success('Project created sucessfully');
      dispatch(getAllProject(courseId));
    // return;
  
    // dispatch(handleLoading());
  } catch ({ response, message }) {
    // dispatch({
    //   type: SET_PROJECT_ERRORS,
    //   payload: error.message,
    // });
    return response?.data || message;
  }
};

export const updateProject = (projectId,projectdata, courseId, toast) => async (dispatch) => {
  try {
    const { data } = await sendRequest(
      `/task/project/${projectId}/`,
      projectdata,
      'PATCH',
    );
    dispatch({
      type: GET_PROJECT,
      payload: data,
    });
    dispatch(getAllProject(courseId));
        toast.success('Project updated sucessfully');
    return;

    // dispatch(handleLoading());
  } catch ({ response, message }) {
    // dispatch({
    //   type: SET_PROJECT_ERRORS,
    //   payload: error.message,
    // });
    return response?.data || message;
  }
};



export const getProjectStudents = (projectId) => async (dispatch) => {
  try {
    const { data } = await sendRequest(`/task/project/${projectId}/users/`);
    dispatch({
      type: GET_PROJECT_STUDENTS,
      payload: data,
    });
    //   dispatch(handleLoading());
  } catch (error) {
    dispatch({
      type: SET_PROJECT_ERRORS,
      payload: error.response,
    });
  }
};



export const getAllProjectUserSearch = (link) => async (dispatch) => {
 try {
   const { data } = await sendRequest(
    link,
     'GET',
   );

   dispatch({
     type: GET_PROJECT_STUDENTS,
     payload: data,
   });
return 
   // dispatch(handleLoading());
 } catch (error) {
   dispatch({
     type: SET_PROJECT_ERRORS,
     payload: error.message,
   });
 }

};

export const gradeStudentsProject = (grade, projectId, toast) => async (dispatch) => {

  try {
    const { data } = await sendRequest(
      `/task/project_result/${projectId}/instructor/`,
      grade,
      'PATCH',
    );
    dispatch({
      type: GRADE_STUDENT_PROJECTS,
      payload: data,
    });

    toast.success('Student Graded sucessfully');
    return
  } catch ({ response, message }) {
    return response?.data || message;
  }
};




export const deleteProject = (projectId, courseId,toast) => async (
  dispatch,
) => {
  try {
  await sendRequest(
      `/task/project/${projectId}/`, null,
      'DELETE',
    );
 
    toast.success('Project deleted sucessfully');
    dispatch(getAllProject(courseId));
    return;
  } catch ({ response, message }) {
    return response?.data || message;
  }
};
